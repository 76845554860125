/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: .3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0,0,0,0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0,0,0,0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0,0,0,0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0,0,0,0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255,255,255,0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255,255,255,0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40,167,69,0.9);
  border-radius: .25rem;
}

.form-row>.col>.valid-tooltip,
.form-row>[class*="col-"]>.valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220,53,69,0.9);
  border-radius: .25rem;
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*="col-"]>.invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem rgba(38,143,255,0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38,143,255,0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130,138,145,0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130,138,145,0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem rgba(72,180,97,0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(72,180,97,0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem rgba(58,176,195,0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(58,176,195,0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem rgba(222,170,12,0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222,170,12,0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem rgba(225,83,97,0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(225,83,97,0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem rgba(216,217,219,0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(216,217,219,0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem rgba(82,88,93,0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(82,88,93,0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: .25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: flex;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation)>.form-control:not(:last-child),
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation>.form-control:nth-last-child(n+3),
.input-group.has-validation>.custom-select:nth-last-child(n+3),
.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0,123,255,0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0,123,255,0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0,123,255,0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0,123,255,0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0,0,0,0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0,0,0,0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0,0,0,0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0,0,0,0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0,0,0,0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,0.5);
  border-color: rgba(0,0,0,0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0,0,0,0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0,0,0,0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0,0,0,0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255,255,255,0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255,255,255,0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,0.5);
  border-color: rgba(255,255,255,0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255,255,255,0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0,0,0,0.03);
  border-top: 1px solid rgba(0,0,0,0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(108,117,125,0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(23,162,184,0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,193,7,0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(248,249,250,0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(52,58,64,0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: rgba(255,255,255,0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,0.1);
  opacity: 0;
  border-radius: .25rem;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255,255,255,0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: .3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-footer>* {
  margin: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: .3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0,0,0,0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0,0,0,0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0,0,0,0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0,0,0,0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1,
.my-1 {
  margin-top: .25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2,
.my-2 {
  margin-top: .5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1,
.py-1 {
  padding-top: .25rem !important;
}

.pr-1,
.px-1 {
  padding-right: .25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important;
}

.pl-1,
.px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

.pr-2,
.px-2 {
  padding-right: .5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pl-2,
.px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
}

.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0,0,0,0.5) !important;
}

.text-white-50 {
  color: rgba(255,255,255,0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #616161;
  font-size: 14px;
}

.hidden {
  display: none;
}

#pjAcceptCookieBar {
  background-color: #d14b52 !important;
  box-shadow: 0 -5px 20px #666666;
}

#pjAcceptCookieBar a {
  color: #ffffff;
  border-bottom: 1px dotted;
}

#pjAcceptCookieBar a:hover {
  color: #ffffff;
  border-bottom: 1px dotted;
}

#pjAcceptCookieBar .pjAcceptCookieBarBtn {
  background-color: #ffffff !important;
  color: #d14b52 !important;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 30px !important;
  margin-top: 10px !important;
}

a {
  color: #666666;
}

a:hover {
  text-decoration: none;
  color: #333333;
}

a:hover.svg {
  position: relative;
  display: inline-block;
}

a:hover.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

a.svg {
  position: relative;
  display: inline-block;
}

a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.clear-both {
  clear: both;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.header {
  padding-top: 20px;
}

.header .custom-button {
  width: 100%;
  background-color: #d14b52;
  color: #ffffff;
  padding: 15px 35px;
  border-radius: 30px;
  text-align: center;
}

.header .custom-button a {
  color: #ffffff;
}

.header .custom-button a object {
  position: relative;
  float: left;
  height: 30px;
  top: -5px;
}

.header .copyright a {
  color: #2c2c2b;
}

.loader {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loader .picto {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader .picto i {
  color: #d14b52;
  font-size: 30px;
}

.custom-button {
  width: 100%;
  background-color: #d14b52;
  color: #ffffff;
  padding: 15px 35px;
  border-radius: 30px;
  text-align: center;
}

.custom-button a {
  color: #ffffff;
}

.custom-button a object {
  position: relative;
  float: left;
  height: 30px;
  top: -5px;
}

.menu {
  background-color: #b47c5a;
  margin: 20px 0 0 0;
  padding: 15px 0;
  height: 50px;
}

.menu .item {
  float: left;
  padding: 0 20px;
  text-transform: uppercase;
}

.menu .item:first-of-type {
  padding-left: 0;
}

.menu .item a {
  color: #ffffff;
}

.menu .item-extern {
  font-size: 13px;
  float: right;
}

.menu .item-extern a {
  opacity: 0.7;
}

.menu .item-extern a object {
  position: relative;
  top: 3px;
  margin: 0 8px 0 0;
}

.menu-top {
  background-color: #f0f0f0;
  margin: 0;
  padding: 10px 0;
  height: 40px;
}

.menu-top a {
  font-size: 13px;
  color: #707070;
}

.menu-top a i {
  color: #4e4e4e;
  padding-right: 7px;
}

.menu-top .item {
  float: left;
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.menu-top .item-first {
  padding-left: 0;
  border-right: 1px solid #c6c6c6;
}

.menu-top .item-extern {
  font-size: 13px;
  float: right;
  text-transform: none;
}

.menu-top .item-last {
  padding-right: 0;
}

.footer-newsletter {
  background-image: url(/img/FD_newsletter.svg);
  background-position: center;
  background-size: cover;
  background-color: #b47c5a;
  margin: 20px 0 0 0;
  padding: 40px 0;
  color: #ffffff;
  text-align: center;
}

.footer-newsletter h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 200;
  padding-bottom: 0;
}

.footer-newsletter h3 {
  font-size: 16px;
  font-weight: 300;
  padding: 0 0 20px 0;
}

.footer-newsletter input[type="text"] {
  width: 80%;
  margin: 0;
  float: left;
  padding: 15px 35px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: none;
  color: #5a5a5a;
}

.footer-newsletter input[type="submit"] {
  width: 20%;
  margin: 0;
  float: left;
  padding: 15px 0;
  text-align: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;
  background-color: #eae7de;
  font-size: 14px;
  color: #333333;
  text-transform: uppercase;
}

.footer-newsletter .custom-button {
  background-color: #eae7de;
  color: #333333;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 30px;
  font-weight: 500;
}

.footer-links {
  background-color: #eae7de;
  margin: 0;
  padding: 40px 0;
}

.footer-links .header {
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;
  font-weight: 600;
}

.footer-links span {
  font-weight: 300;
}

.footer-links .row {
  margin-bottom: 20px;
  line-height: 25px;
}

.footer-links .row:last-of-type {
  margin-bottom: 0;
}

.footer-links a {
  color: #666666;
  font-weight: 300;
}

.footer-links a .link-bold {
  font-weight: 400;
}

.footer-links object {
  position: relative;
  margin-right: 20px;
}

.footer-links .social-links {
  padding: 20px 15px;
}

.footer-links .social-links a {
  color: #b47c5a;
  padding-right: 15px;
}

.footer-links .social-links a i {
  font-size: 20px;
}

.footer-links2 {
  padding: 30px 0;
  color: #acacac;
  font-size: 13px;
  font-weight: 300;
}

.footer-links2 a {
  color: #acacac;
  font-weight: 400;
}

.footer-links2 .align-right {
  text-align: right;
}

.page-infos {
  text-align: center;
}

.page-infos h1 {
  padding: 50px 0 15px 0;
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
}

.page-infos h2 {
  padding: 50px 0 15px 0;
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
}

.page-infos h3 {
  padding: 50px 0 15px 0;
  font-family: 'Poiret One', cursive;
  font-size: 40px;
  color: #333333;
  text-transform: uppercase;
}

.page-infos object {
  position: relative;
  padding: 30px 0;
}

.page-infos span {
  font-size: 16px;
  color: #333333;
  font-weight: 300;
  line-height: 30px;
}

.page-infos .participate {
  padding: 45px 15px;
}

.page-infos .participate a.custom-button {
  text-transform: uppercase;
  color: #ffffff;
}

.page-infos .custom-button {
  border: none;
  text-transform: uppercase;
}

.page-infos .story-stats-box span {
  position: absolute;
  top: -7px;
  left: 15px !important;
  font-size: 10px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.page-contest .story-stats-box span {
  position: absolute;
  top: -7px;
  left: 24px !important;
  font-size: 10px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.breadcrumbs {
  padding: 15px 0 15px 40px;
  border-bottom: 1px solid #eaeaea;
  font-size: 13px;
  color: #666666;
  text-align: left;
}

.breadcrumbs a {
  color: #acacac;
}

.connect-register {
  padding: 30px 15px;
  font-size: 15px;
  color: #616161;
  line-height: 25px;
}

.connect-register h2 {
  font-size: 35px;
  color: #616161;
  font-weight: 300;
  margin-bottom: 20px;
}

.connect-register span {
  font-weight: 300;
}

.connect-register .form-content {
  padding: 20px 15px;
}

.connect-register .form-content form {
  width: 100%;
}

.connect-register .form-content label {
  font-size: 12px;
  color: #b47c5a;
  font-weight: 500;
  text-transform: uppercase;
}

.connect-register .form-content input[type="text"],
.connect-register .form-content input[type="password"],
.connect-register .form-content input[type="email"] {
  width: 100%;
  border: 2px solid #f1e9dc;
  font-size: 14px;
  color: #b47c5a;
  padding: 10px;
}

.connect-register .form-content .form-box {
  padding: 0 0 10px 0;
}

.connect-register .form-content button {
  background-color: #b47c5a;
  border: none;
  text-transform: uppercase;
}

.connect-register .form-content a {
  font-size: 14px;
  color: #616161;
  border-bottom: 1px solid #616161;
}

.connect-register .form-content .lost-password {
  padding: 15px;
}

.connect-register .form-content ul {
  font-size: 12px;
  color: #e86a6a;
  font-style: italic;
  font-weight: 400;
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
  width: -webkit-fill-available;
}

.connect-register .form-content .error label {
  color: #e86a6a;
}

.connect-register .form-content .error input {
  border-color: #e86a6a;
  color: #e86a6a;
}

.connect-register .form-content .error span {
  display: block;
}

.connect-register .success {
  color: #616161;
  text-align: center;
  padding: 0 0 30px 0;
}

.connect-register .hidden {
  display: none;
}

.connected-infos {
  padding: 15px 0 0 0;
  text-align: center;
}

.connected-infos .header-superadmin {
  padding: 10px 0 0 0;
}

.connected-infos .header-superadmin a {
  background-color: #b5b5b5;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  break-inside: avoid;
  padding: 15px 13px;
}

.connected-infos .header-login {
  padding: 0;
}

.connected-infos .header-login a {
  color: #d14b52;
  font-size: 14px;
  font-weight: 600;
  float: right;
  padding: 10px 0 0 0;
}

.connected-infos .header-login .header-image-login {
  width: 40px;
  height: 40px;
  background-color: #d14b52;
  border-radius: 50%;
  float: right;
  margin: 0 5px 0 0;
  background-position: center;
  background-size: cover;
}

.connected-infos .header-pal,
.connected-infos .header-alert {
  padding: 10px 0 0 0;
}

.connected-infos .header-pal a,
.connected-infos .header-alert a {
  color: #616161;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0 0 0;
}

.connected-infos .header-pal .header-pal-nb,
.connected-infos .header-pal .header-alert-nb,
.connected-infos .header-alert .header-pal-nb,
.connected-infos .header-alert .header-alert-nb {
  position: absolute;
  top: 0;
  right: 15px;
  background-color: #e5e5e5;
  color: #d14b52;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 8px;
}

.connected-infos .header-pal .header-alert-nb,
.connected-infos .header-alert .header-alert-nb {
  background-color: #d14b52;
  color: #ffffff;
  right: 0;
}

.modal .modal-dialog {
  max-width: 600px;
  margin-top: 45px;
}

@media screen and (max-width: 992px) {
  .modal .modal-dialog {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 45px;
  }
}

.modal .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 600px;
}

@media screen and (max-width: 992px) {
  .modal .modal-dialog .modal-content {
    width: 100%;
  }
}

.modal .modal-dialog .modal-content a {
  color: #616161;
  border-bottom: 1px solid #616161;
}

.modal .modal-dialog .modal-content .modal-header {
  border: none;
}

.modal .modal-dialog .modal-content .modal-header .close {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-content .modal-body {
  background-color: #ffffff;
  padding: 30px 45px;
}

.modal .modal-dialog .modal-content .modal-body h2 {
  color: #323232;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
}

.modal .modal-dialog .modal-content .modal-body .subtext {
  color: #616161;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  padding: 0 0 30px 0;
}

.modal .modal-dialog .red-modal .modal-body {
  background-color: #d14b52;
}

.modal .modal-dialog .red-modal .modal-body h2 {
  color: #ffffff;
}

.modal .modal-dialog .red-modal .modal-body .subtext {
  color: #ffffff;
  opacity: 0.85;
}

.modal .modal-dialog .red-modal .modal-body .subtext .link {
  padding: 20px 0 15px 0;
}

.modal .modal-dialog .red-modal .modal-body select {
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #dadada;
}

.modal .modal-dialog .red-modal .modal-body .custom-button {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #d14b52;
}

.modal .modal-dialog .red-modal .modal-body ul {
  color: #ffffff;
  text-align: left;
}

.modal .modal-dialog .red-modal .modal-body .error {
  display: none;
}

.modal .modal-dialog .contact-modal .modal-body .error {
  display: none;
}

.contact span,
.contact label {
  color: #2c2c2b;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.contact input[type="text"],
.contact input[type="email"],
.contact textarea,
.contact select {
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #dadada;
}

.contact textarea {
  height: 150px;
  resize: none;
}

.contact input[type="submit"] {
  border: none;
  text-transform: uppercase;
}

.contact .confirm {
  margin: 60px 0 15px 0;
  text-align: center;
}

.contact .confirm .title {
  color: #167b7b;
  font-size: 30px;
  font-weight: 600;
  padding: 10px 0;
}

.contact .confirm .text {
  color: #616161;
  font-size: 15px;
  font-weight: 500;
}

.contact ul {
  font-size: 12px;
  color: #e86a6a;
  font-style: italic;
  font-weight: 400;
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
  width: -webkit-fill-available;
}

.navbar {
  width: 100%;
}

.navbar .navbar-toggler {
  width: inherit;
  text-align: right;
}

.mobile-menu {
  background-color: #b47c5a;
}

.mobile-menu .nav-item {
  text-transform: uppercase;
  padding: 0 30px;
  text-align: center;
}

.mobile-menu .nav-item a {
  color: #ffffff;
  font-weight: 500;
}

.mobile-menu .nav-item .header-image-login {
  width: 40px;
  height: 40px;
  background-color: #d14b52;
  border-radius: 50%;
  margin: auto;
  background-position: center;
  background-size: cover;
}

.mobile-menu .connectregister {
  padding: 10px 30px;
  border-bottom: 1px solid #eae7de;
}

.mobile-menu .search {
  border-bottom: 1px solid #eae7de;
}

.mobile-menu .external {
  opacity: 0.7;
}

.mobile-menu .connecteduser {
  padding: 10px 0;
}

.error-page h1 {
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
  text-align: center;
  padding: 60px 0 15px 0;
}

.error-page .text {
  font-size: 16px;
  color: #2c2c2b;
  text-align: center;
  padding: 15px 15px 35px 15px;
  line-height: 35px;
}

.error-page .pills {
  padding: 15px;
}

.error-page .pills a {
  color: #ffffff;
  text-transform: uppercase;
}

.error-page .pills .red-pill {
  background-color: #e20707;
}

.error-page .pills .blue-pill {
  background-color: #29a9e0;
}

.register .picto {
  padding-right: 5px;
}

.page-big-header {
  height: 500px;
}

.page-big-header .background {
  position: absolute;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
}

.page-big-header .background-dark {
  position: absolute;
  width: 100%;
  height: 500px;
  opacity: 0.6;
  background-color: #000000;
}

.page-big-header .big-header-infos-author {
  padding: 110px 15px 0 15px;
}

.page-big-header .big-header-infos-story {
  padding: 80px 15px 0 15px;
}

.page-big-header .big-header-infos .header-image-author {
  position: relative;
  width: 260px;
  height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 3px solid #e5e5e5;
}

@media screen and (max-width: 1200px) {
  .page-big-header .big-header-infos .header-image-author {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 992px) {
  .page-big-header .big-header-infos .header-image-author {
    width: 160px;
    height: 160px;
  }
}

.page-big-header .big-header-infos .title {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 55px;
  font-weight: 500;
  color: #ffffff;
  text-transform: none;
  line-height: 55px;
  padding: 15px 0 20px 0;
}

.page-big-header .big-header-infos span {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}

.page-big-header .big-header-infos span a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.page-big-header .big-header-infos .social {
  position: relative;
  margin: 20px 0;
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
}

.page-big-header .big-header-infos .social a {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
}

.page-big-header .big-header-infos .social a.social-mini {
  float: right;
  margin: 0 10px;
}

.page-big-header .big-header-infos .social .story-stats-box {
  float: left;
  color: #ffffff;
  padding: 0 15px 0 0;
}

.page-big-header .big-header-infos .header-infos {
  padding-top: 25px;
}

.page-big-header .big-header-infos .header-infos h1 {
  color: #ffffff;
}

.page-big-header .big-header-infos .header-image-story {
  position: relative;
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 3px solid #e5e5e5;
}

@media screen and (max-width: 1200px) {
  .page-big-header .big-header-infos .header-image-story {
    width: 200px;
    height: 280px;
  }
}

@media screen and (max-width: 992px) {
  .page-big-header .big-header-infos .header-image-story {
    width: 160px;
    height: 220px;
    margin-top: 45px;
  }
}

.page-big-header .big-header-infos .list-tag {
  float: left;
  padding: 0 5px 0 0;
}

.page-big-header .big-header-infos .list-tag span {
  color: #ffffff;
  font-size: 11px;
  padding: 5px 10px;
  background-color: #b47c5a;
  text-transform: uppercase;
}

.account-menu .account-menu-link {
  width: 20%;
  float: left;
  font-size: 17px;
  padding: 30px 0 20px 0;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  border-bottom: 2px solid #ffffff;
}

@media screen and (max-width: 992px) {
  .account-menu .account-menu-link {
    width: 33%;
  }
}

@media screen and (max-width: 768px) {
  .account-menu .account-menu-link {
    width: 50%;
  }
}

.account-menu .account-menu-link a {
  color: #333333;
}

.account-menu .account-menu-link.active {
  border-bottom: 2px solid #d14b52;
}

.account-menu .account-menu-link.active a {
  color: #d14b52;
}

.account-avertissement {
  padding: 15px 30px;
  background-color: #d14b52;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
}

.account-avertissement a {
  color: #ffffff;
  border-bottom: 1px dotted;
  font-weight: 500;
}

.account-avertissement i {
  font-size: 18px;
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.page-detail {
  padding: 40px 15px 15px 15px;
}

.page-detail .text {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2b;
  padding-bottom: 20px;
}

.page-detail .text span {
  font-size: 60px;
  color: #d14b52;
  font-weight: 700;
}

.page-detail .stats {
  text-align: center;
}

.page-detail .stats .title {
  position: relative;
  font-size: 18px;
  color: #d14b52;
  text-transform: uppercase;
  padding: 0 15px 20px 15px;
  font-weight: 600;
}

.page-detail .stats .stat-box {
  position: relative;
  padding: 15px 15px;
  font-size: 13px;
  color: #333333;
  font-weight: 500;
  text-transform: uppercase;
}

.page-detail .stats .stat-box span {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  color: #d14b52;
  font-size: 70px;
  font-weight: 700;
  line-height: 50px;
}

.page-detail .stats .action {
  margin: 5px 0;
  text-transform: uppercase;
}

.page-detail .stats .contact {
  background-color: #e5e5e5;
}

.page-detail .stats .contact a {
  color: #2c2c2b;
}

.page-detail .stats .follow a {
  color: #ffffff;
}

.page-detail .stats .unfollow {
  background-color: #ffffff;
  border: 2px solid #d14b52;
}

.page-detail .stats .unfollow a {
  color: #d14b52;
}

.page-detail .stats .report {
  padding: 15px 15px;
}

.page-detail .stats .report a {
  font-size: 14px;
  color: #616161;
  font-weight: 300;
  font-style: italic;
  text-transform: none;
  border-bottom: 1px solid #616161;
}

.page-detail .stats .author-img {
  height: 160px;
  width: 160px;
  margin: auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.page-detail .stats .author-infos {
  padding: 20px 0 30px 0;
}

.page-detail .stats .author-infos span {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  color: #2c2c2b;
  font-size: 30px;
  font-weight: 600;
}

.page-detail .stats .author-infos a {
  font-size: 13px;
  color: #d14b52;
  border-bottom: 1px solid #d14b52;
  text-transform: uppercase;
}

.page-detail .stats .custom-button-ho {
  background-color: #db9534;
}

.page-detail .header {
  padding: 0 0 40px 0;
}

.page-detail .header .custom-button {
  position: relative;
  width: 225px;
  background-color: #f6f6f6;
  color: #2c2c2b;
  padding: 15px 35px;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  float: left;
  font-weight: 500;
  margin-right: 5px;
}

.page-detail .header .rating {
  color: #d14b52;
  text-transform: uppercase;
}

.page-detail .header h1 {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  color: #d14b52;
  font-size: 55px;
  font-weight: 600;
  line-height: 55px;
}

.page-detail .header .saga {
  font-size: 22px;
  color: #333333;
  font-weight: 300;
  padding-bottom: 15px;
}

.page-detail .header .status {
  font-size: 14px;
  padding-bottom: 10px;
}

.page-detail .header .status-encours {
  color: #23a44e;
}

.page-detail .header .status-encours a {
  color: #23a44e;
  border-bottom: 1px dotted #23a44e;
}

.page-detail .header .status-enhiatus {
  color: #a42323;
}

.page-detail .header .status-enrecriture {
  color: #a47023;
}

.page-detail .header .status-enrecriture a {
  color: #a47023;
  border-bottom: 1px dotted #a47023;
}

.page-detail .header .infos {
  font-size: 14px;
  color: #acacac;
  line-height: 25px;
}

.page-detail .chapter {
  padding: 10px 0;
  border-bottom: 1px solid #c2c2c2;
}

.page-detail .chapter a {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}

.page-detail .chapter .chapter-title {
  padding-top: 8px;
}

.page-detail .chapter .social {
  padding-top: 8px;
}

.page-detail .chapter .social .story-stats-box {
  float: right;
}

.page-detail .chapter .round {
  position: relative;
  font-size: 14px;
  width: 25px;
  height: 25px;
  background-color: #f6f6f6;
  color: #b5b5b5;
  border-radius: 50%;
  padding: 2px;
  text-align: center;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.page-detail .chapter .state {
  font-size: 12px;
  color: #b5b5b5;
  font-weight: 500;
  text-transform: uppercase;
}

.page-detail .chapter .state .state-text {
  padding: 0;
  cursor: pointer;
}

.page-detail .chapter .state.active .round {
  background-color: #d14b52;
  color: #ffffff;
}

.page-detail .chapter .state.active .state-text {
  color: #d14b52;
  padding-top: 10px;
}

.page-detail .story-stats-box {
  position: relative;
  font-size: 14px;
  float: left;
  padding: 0 20px 0 0;
  color: #666666;
}

.page-detail .content {
  padding-bottom: 30px;
}

.page-detail .pal-hide {
  text-align: right;
  padding: 15px;
}

.page-detail .pal-hide a {
  color: #2c2c2b;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0 0;
  margin-bottom: 0;
}

.account-params-item .round {
  position: relative;
  font-size: 14px;
  width: 25px;
  height: 25px;
  background-color: #f6f6f6;
  color: #b5b5b5;
  border-radius: 50%;
  padding: 2px;
  text-align: center;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

.account-params-item .active round {
  background-color: #d14b52;
  color: #ffffff;
}

.account-params-item .message {
  text-align: center;
  padding: 15px;
}

.account-params-item .message a.custom-button {
  color: #ffffff;
  text-transform: uppercase;
}

.account-content {
  background-color: #f6f6f6;
}

.account-content h1 {
  font-family: 'Poiret One', cursive;
  color: #2c2c2b;
  font-size: 40px;
  text-transform: uppercase;
}

.account-content .see-read {
  padding: 15px 0;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
}

.account-content .see-read a {
  color: #616161;
}

.account-content .see-read a i.check {
  color: #d14b52;
  font-size: 20px;
}

.account-content .red-button {
  width: 100%;
  background-color: #d14b52;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
}

.account-content a.red-button {
  color: #ffffff;
  float: right;
  text-align: center;
  margin: 20px 0 0 0;
}

.account-content .account-image-story {
  position: relative;
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
}

.account-content .account-image {
  width: 80px;
  height: 80px;
  background-color: #d14b52;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.account-content .subscriber-image {
  width: 130px;
  height: 130px;
  background-color: #d14b52;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin: auto;
}

.account-content .account-submenu {
  padding: 15px;
}

.account-content .account-submenu .account-submenu-item {
  padding: 15px 0;
}

.account-content .account-submenu .account-submenu-item a {
  color: #666666;
  font-weight: 500;
}

.account-content .account-submenu .account-submenu-item a i {
  padding-right: 15px;
}

.account-content .account-story-item {
  background-color: #ffffff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
}

.account-content .account-story-item .story-img {
  width: 100%;
  height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.account-content .account-story-item .maj {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 5px;
}

.account-content .account-story-item .story-genres .story-genres-box {
  background-color: #b5b5b5;
}

.account-content .account-story-item .title a {
  color: #333333;
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
}

.account-content .account-story-item .chapters {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
}

.account-content .account-story-item .saga {
  font-size: 22px;
  color: #333333;
  font-weight: 300;
  padding-bottom: 5px;
}

.account-content .account-story-item .authors {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #b5b5b5;
}

.account-content .account-story-item .authors a {
  color: #b47c5a;
  font-weight: 600;
}

.account-content .account-story-item .authors-pal {
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.account-content .account-story-item .action-buttons {
  padding: 45px 15px;
  text-align: center;
}

.account-content .account-story-item .action-buttons .custom-button.remove {
  background-color: #e5e5e5;
  font-size: 14px;
  text-transform: uppercase;
}

.account-content .account-story-item .action-buttons a.remove {
  color: #2c2c2b;
  font-weight: 600;
}

.account-content .account-story-item .social {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #b5b5b5;
}

.account-content .account-story-item .social-pal {
  border-top: none;
  margin-top: auto;
  padding-top: auto;
}

.account-content .account-story-item .no-followers {
  font-size: 18px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 20px 0 25px 0;
  text-align: center;
}

.account-content .account-story-item .no-followers .message {
  padding: 10px 0;
}

.account-content .account-story-item .no-followers a.custom-button:hover {
  color: #ffffff;
}

.account-content .account-story-item .item {
  margin: 5px 15px;
}

.account-content .account-story-item .item .name {
  font-size: 14px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.account-content .account-story-item .help {
  font-style: italic;
  padding: 10px 10px;
  text-align: right;
}

.account-content .account-params-item {
  padding: 30px 45px;
}

.account-content .account-params-item a {
  color: #b47c5a;
}

.account-content .account-params-item .title {
  color: #2c2c2b;
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 15px;
  border-bottom: 1px solid #2c2c2b;
  margin-bottom: 15px;
}

.account-content .account-params-item label {
  color: #2c2c2b;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0 0 0;
  margin-bottom: 0;
}

.account-content .account-params-item .info {
  color: #666666;
  font-size: 12px;
  font-weight: 300;
}

.account-content .account-params-item input[type="text"],
.account-content .account-params-item input[type="email"],
.account-content .account-params-item input[type="password"],
.account-content .account-params-item textarea {
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #dadada;
}

.account-content .account-params-item input[type="file"] {
  width: 100%;
}

.account-content .account-params-item .resume {
  height: 200px;
  resize: none;
}

.account-content .account-params-item .checkbox div {
  padding: 10px 5px;
}

.account-content .account-params-item select {
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #dadada;
}

.account-content .account-params-item .state-text {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.account-content .account-params-item .active .state-text {
  color: #d14b52;
}

.account-content .account-params-item .account-notifs {
  padding: 8px 0 0 0;
}

.account-content .account-params-item .account-notifs .state-text {
  padding: 10px;
  font-weight: 500;
}

.account-content .account-params-item .chapter .chapter-edit a {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
}

.account-content .account-params-item .chapter .chapter-delete a {
  color: #d14b52;
  font-size: 14px;
  font-weight: 600;
}

.account-content .account-params-item .picture label {
  padding-top: 0;
  width: 100%;
}

.account-content .account-params-item .picture input {
  padding: 10px 0;
}

.account-content ul {
  font-size: 12px;
  color: #e86a6a;
  font-style: italic;
  font-weight: 400;
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
  width: -webkit-fill-available;
}

.account-content ul.active {
  color: #23a44e;
}

.account-content .chapter-content {
  padding: 0 15px 15px 15px;
}

.account-content .account-warning {
  background-color: #db9534;
  color: #ffffff;
  padding-top: 30px;
}

.account-content .account-warning .title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.account-content .account-warning .date {
  font-size: 14px;
}

.account-content .account-warning .message {
  padding-left: 15px;
  border-left: 3px solid #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}

.account-content .account-warning .plus {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid #ffffff;
  cursor: pointer;
  padding-top: 15px;
}

.account-content .account-warning .plus-texte {
  background-color: #ffffff;
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2b;
  padding: 30px 45px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
  margin-bottom: 15px;
}

.account-content .account-warning .plus-texte p {
  text-indent: 30px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d14b52;
  border-color: #d14b52;
}

.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #adb5bd;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.toggle span {
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154,153,153,0.5);
  transition: all 0.2s ease;
}

.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(209,75,82,0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.cbx {
  display: none;
}

.cbx:checked+.toggle:before {
  background: #d14b52;
}

.cbx:checked+.toggle span {
  background: #ffffff;
  border: 2px solid #d14b52;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),background 0.15s ease;
  box-shadow: 0 3px 8px rgba(209,75,82,0.2);
}

.cbx:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.authors .authors-filters {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 576px) {
  .authors .authors-filters {
    padding: 20px 20px;
  }
}

.authors .authors-filters .authors-filter-box {
  float: left;
  width: 43px;
  text-align: center;
  font-size: 15px;
  padding: 10px 15px;
}

.authors .authors-filters .authors-filter-box a {
  color: #b5b5b5;
}

.authors .authors-filters .authors-filter-box.active a {
  color: #ffffff;
}

.authors .authors-filters .authors-filter-box.active {
  background-color: #b47c5a;
  border-radius: 50%;
  width: 42px;
}

@media screen and (max-width: 1200px) {
  .authors .authors-filters .authors-filter-box.active {
    width: 42px;
  }
}

.authors-list .authors-list-box {
  text-align: center;
  padding-top: 30px;
}

.authors-list .authors-list-box .authors-list-img {
  width: 100%;
  height: 260px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: auto;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .authors-list .authors-list-box .authors-list-img {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 992px) {
  .authors-list .authors-list-box .authors-list-img {
    width: 240px;
    height: 240px;
  }
}

.authors-list .over-author {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  padding: 70px 0 0 0;
  display: none;
  text-align: center;
  border-radius: 50%;
}

.authors-list .over-author span {
  font-size: 43px;
  color: #ffffff;
}

.authors-list .over-author a {
  font-size: 14px;
  font-weight: 200;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 25px;
}

.author-list-tag {
  text-align: center;
  padding-top: 30px;
}

.author-list-tag span {
  color: #ffffff;
  font-size: 11px;
  padding: 5px 10px;
  background-color: #b47c5a;
  text-transform: uppercase;
}

.author-list-stories {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
  border-bottom: 1px solid #b47c5a;
  padding: 0 0 20px 0;
}

.author-list-name {
  padding: 15px 0 0 0;
}

.author-list-name a {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 30px;
  font-weight: 600;
  color: #333333;
}

.load-more {
  font-size: 14px;
  color: #d14b52;
  text-transform: uppercase;
  text-align: center;
  padding: 50px 0;
  line-height: 25px;
}

.load-more i {
  font-size: 30px;
  cursor: pointer;
}

.load-more a {
  color: #d14b52;
}

.load-more a object {
  position: relative;
  border: 2px solid #d14b52;
  border-radius: 50%;
  padding: 10px 13px;
  margin: 10px;
}

.page-content {
  font-size: 16px;
  color: #2c2c2b;
  line-height: 35px;
  font-weight: 300;
}

.page-content a {
  color: #b47c5a;
}

.page-content strong {
  font-weight: 600;
}

.page-content h1 {
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
  padding: 15px 0 15px 0;
}

@media screen and (max-width: 992px) {
  .page-content h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .page-content h1 {
    font-size: 40px;
  }
}

.page-content h2 {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 28px;
  color: #2c2c2b;
  font-weight: 600;
  padding: 30px 0 10px 0;
}

.page-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.page-content ul li {
  padding-bottom: 10px;
}

.page-content ul li:before {
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f192";
  padding-right: 10px;
  color: #b47c5a;
}

.page-content blockquote {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  border-left: 6px solid #b47c5a;
  margin: 30px 0;
  padding: 0 30px;
  font-size: 30px;
  font-style: italic;
  line-height: 40px;
}

.page-content .date {
  color: #808080;
  font-size: 11px;
  font-weight: 500;
  padding: 15px 0 0 0;
}

.page-content .date a {
  color: #808080;
  font-weight: 600;
}

.page-content .back {
  color: #b47c5a;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 30px 0 3px;
  border-bottom: 1px solid #f1e9dc;
  margin: 0 0 30px 0;
}

.page-content .back a {
  color: #b47c5a;
}

.page-content .image {
  text-align: center;
  margin: 30px 0;
}

.page-content .image .legend {
  color: #808080;
  font-size: 11px;
  font-weight: 500;
}

.stories-list {
  padding: 30px 15px 0 15px;
}

.stories-list .box-story .events-list .box-event {
  width: 100%;
  margin-bottom: 40px;
}

.stories-list .comment-title {
  font-size: 18px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 20px 0 20px 0;
}

.stories-list .comment-title span {
  color: #b47c5a;
}

.events-list .single-event {
  padding: 0 15px 15px 15px;
}

.box-story {
  position: relative;
  width: 100%;
  height: 555px;
  float: left;
  padding: 0 5px 0 0;
}

.box-story .story-img {
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.box-story .over-infos {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  padding: 95px 0 0 0;
  display: none;
  text-align: center;
}

.box-story .over-infos .over-infos-box {
  position: relative;
  width: 150px;
  padding: 15px 0;
  font-weight: 200;
  font-size: 20px;
  text-transform: uppercase;
  border-top: 1px solid #ffffff;
  margin: auto;
}

.box-story .over-infos .over-infos-box i {
  font-size: 30px;
}

.box-story .over-infos .over-infos-box a {
  color: #ffffff;
}

.box-story .over-infos .over-infos-box:first-of-type {
  border-top: none;
}

.box-story .story-title {
  position: relative;
  padding: 10px 0 0 0;
  text-align: left;
  font-weight: 300;
  clear: both;
  height: 125px;
  border-bottom: 1px solid #b47c5a;
}

.box-story .story-title a {
  color: #666666;
}

.box-story .story-title a:hover {
  color: #b47c5a;
}

.box-story .story-title span {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 26px;
  font-weight: 600;
}

.box-story .story-title span a {
  color: #000000;
}

.box-story .story-stats {
  padding: 10px 0;
}

.box-story .story-stats .story-stats-box {
  position: relative;
  font-size: 14px;
  float: left;
  padding: 0 20px 0 0;
  color: #666666;
  width: 20%;
}

.box-story .story-stats .story-stats-box i {
  font-size: 18px;
}

.box-story .story-stats .story-stats-box span {
  position: absolute;
  top: -7px;
  left: 7px;
  font-size: 10px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.box-event {
  height: auto;
}

.box-event .story-img {
  height: 250px;
}

.box-event .story-title {
  height: auto;
}

.story-genres {
  position: relative;
  padding: 15px 0;
  font-size: 11px;
  color: #ffffff;
  text-transform: uppercase;
  height: 71px;
}

.story-genres .story-genres-box {
  position: relative;
  float: left;
  padding: 5px 8px;
  margin: 0 3px 3px 0;
  background-color: #b47c5a;
  font-size: 10px;
}

.story-genres .story-genres-box a {
  color: #ffffff;
}

@media screen and (max-width: 1200px) {
  .story-genres .story-genres-box {
    font-size: 8px;
    padding: 4px 5px;
  }
}

.comments .author-img {
  height: 70px;
  width: 70px;
  margin: auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.comments .single-item {
  margin: 30px 0;
}

.comments .single-item .comment-title {
  font-size: 18px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 20px 0 20px 0;
}

.comments .single-item .comment-title span {
  color: #b47c5a;
}

.comments .single-item .comment-title a {
  font-size: 18px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 20px 0 20px 0;
}

.comments .single-item .comment-title .tag {
  color: #ffffff;
  font-size: 11px;
  padding: 5px 10px;
  background-color: #b47c5a;
  text-transform: uppercase;
  margin-left: 10px;
}

.comments .single-item textarea {
  border: 2px solid #f1e9dc;
  color: #b47c5a;
  font-size: 14px;
  padding: 15px;
  width: 100%;
  height: 130px;
  resize: vertical;
  line-height: 20px;
}

.comments .single-item .comment-submit {
  margin: 10px 0;
}

.comments .single-item .comment-submit input[type="submit"] {
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 35px;
}

.comments .single-item .comment-submit .submit-consign {
  font-size: 12px;
  color: #666666;
  font-style: italic;
  padding: 15px 0;
}

.comments .single-item .comment-answers textarea {
  height: 75px;
}

.comments .single-item .loader {
  color: #d14b52;
  font-size: 20px;
  text-align: center;
  display: none;
}

.comments .comment-date {
  font-size: 13px;
  color: #b5b5b5;
  font-weight: 300;
}

.comments .comment-body {
  padding: 0 0 10px 0;
  font-size: 14px;
  color: #666666;
  font-weight: 300;
  line-height: 25px;
}

.comments ul {
  font-size: 12px;
  color: #e86a6a;
  font-style: italic;
  font-weight: 400;
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
  width: -webkit-fill-available;
}

.comments ul li:before {
  content: '';
}

.comments .review-answer ul {
  display: none;
}

.account-info-box {
  background-color: #ffffff;
  padding: 15px;
  margin: 15px 0;
  border-radius: 50px;
}

.account-info-box .date {
  color: #616161;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  padding-bottom: 7px;
}

.account-info-box .detail {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
}

.account-info-box .detail a.link-black {
  color: #333333;
  font-weight: 600;
}

.account-info-box .detail a.link-red {
  color: #d14b52;
  font-weight: 600;
}

.account-info-box .detail a.link-comment {
  color: #d14b52;
  border-bottom: 1px solid #d14b52;
}

.account-info-box-read {
  opacity: 0.7;
}

.account-story-add-chapter {
  padding: 0;
  margin: 0;
  background-color: #d14b52;
  text-align: center;
  border-radius: 0 0 5px 5px;
  padding: 20px;
}

.account-story-add-chapter a {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.page-stories {
  background-color: #f6f6f6;
  padding: 50px 15px 20px 15px;
}

.page-stories .title {
  font-size: 18px;
  color: #b47c5a;
  text-transform: uppercase;
  padding: 15px;
}

.stories .stories-filters {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.stories .stories-filters .title {
  font-size: 22px;
  color: #b47c5a;
  padding: 10px 20px;
}

.stories .stories-filters select {
  width: 100%;
  color: #666666;
  font-size: 14px;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #dadada;
}

.stories .stories-filters label {
  font-size: 15px;
  color: #616161;
  padding: 11px 0;
}

.stories .stories-filters input[type="radio"] {
  padding: 15px 5px;
}

.stories .stories-filters input[type="submit"] {
  border: none;
  background-color: #b47c5a;
  text-transform: uppercase;
}

.stories ul {
  font-size: 12px;
  color: #e86a6a;
  font-style: italic;
  font-weight: 400;
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
  width: -webkit-fill-available;
}

.stories form input[type="radio"] {
  display: none;
}

.stories form label {
  padding: 7px;
  font-weight: 500;
  cursor: pointer;
}

.stories form .expanded-row {
  width: 32%;
  text-transform: uppercase;
  float: left;
  border: 1px solid #d14b52;
  color: #616161;
  height: 60px;
  margin: 3px;
}

@media screen and (max-width: 992px) {
  .stories form .expanded-row {
    width: 48%;
  }
}

@media screen and (max-width: 576px) {
  .stories form .expanded-row {
    width: 98%;
    height: initial;
  }
}

.stories form .selected {
  background-color: #d14b52;
  color: #ffffff;
}

#modal-search {
  width: 100%;
  background-color: #d3545b;
  opacity: 0.9;
}

#modal-search .modal-dialog {
  max-width: 600px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  #modal-search .modal-dialog {
    width: 100%;
    margin: auto;
  }
}

#modal-search .modal-dialog .modal-body {
  padding: 60px 0 0 0;
  background-color: #d3545b;
}

#modal-search .modal-dialog .modal-body h2 {
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 30px;
}

#modal-search .modal-dialog .modal-body .subtext {
  color: #ffffff;
  font-size: 16px;
  font-weight: 200;
  padding-bottom: 45px;
}

#modal-search .modal-dialog .modal-body input[type="text"] {
  font-size: 35px;
  color: #ffffff;
  font-weight: 200;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: #d3545b;
  width: 70%;
  float: left;
}

@media screen and (max-width: 768px) {
  #modal-search .modal-dialog .modal-body input[type="text"] {
    font-size: 25px;
  }
}

#modal-search .modal-dialog .modal-body input[type="text"]::placeholder {
  color: #ffffff;
}

#modal-search .modal-dialog .modal-body input[type="submit"] {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
  background-color: #d3545b;
  text-align: right;
  padding: 50px 5px 5px 0;
  width: 30%;
  float: left;
}

@media screen and (max-width: 768px) {
  #modal-search .modal-dialog .modal-body input[type="submit"] {
    font-size: 12px;
    padding-top: 39px;
    text-align: center;
  }
}

.search-results .box {
  height: 550px;
}

.search-results h2 {
  font-family: 'Poiret One', cursive;
  font-size: 40px;
  color: #333333;
  text-transform: uppercase;
  padding-top: 30px;
}

.page-chapter .header {
  width: 80%;
  text-align: center;
  margin: auto;
}

.page-chapter .header h1 {
  font-size: 45px;
  color: #2c2c2b;
}

.page-chapter .header .social {
  margin: auto;
}

.page-chapter .header .social i {
  margin-left: 10px;
}

.page-chapter .header .author {
  margin: 30px 0 20px 0;
  font-size: 13px;
  color: #666666;
  line-height: 25px;
}

.page-chapter .header .author .author-img {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  margin: auto;
}

.page-chapter .header .author a {
  color: #d14b52;
  border-bottom: 1px solid #d14b52;
}

.page-chapter .notes {
  padding: 15px 0 45px 0;
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 20px;
  color: #666666;
  font-style: italic;
}

.page-chapter .text {
  font-size: 20px;
  color: #2c2c2b;
}

.page-chapter .text p {
  padding-bottom: 15px;
  text-indent: 45px;
}

.page-chapter .text p:first-of-type:first-letter {
  font-size: 60px;
  color: #d14b52;
  font-weight: 700;
}

.page-chapter .stats .story-infos span {
  font-size: 30px;
  color: #2c2c2b;
  font-weight: 500;
  line-height: 30px;
}

.page-chapter .stats .story-infos span a {
  color: #2c2c2b;
}

.page-chapter .stats .story-infos select {
  margin: 30px 0;
  width: 80%;
  background-color: #f6f6f6;
  color: #666666;
  font-size: 14px;
  padding: 15px;
  border: none;
}

.page-chapter .stats .story-img {
  width: 160px;
  height: 185px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.page-chapter .stats .contact {
  margin-top: 30px;
}

.page-chapter .navigation {
  margin: 40px 0;
  padding: 40px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
}

.page-chapter .navigation .read {
  border: 2px solid #d14b52;
  background-color: #ffffff;
  padding: 20px 5px;
  border-radius: 50px;
}

.page-chapter .navigation .read a {
  color: #d14b52;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.page-chapter .navigation .read.active {
  background-color: #d14b52;
}

.page-chapter .navigation .read.active a {
  color: #ffffff;
}

.page-chapter .navigation .arrows a {
  color: #b5b5b5;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.page-chapter .navigation .arrows a .round {
  color: #ffffff;
  background-color: #b5b5b5;
  padding: 6px 12px;
  margin: 0 7px;
  border-radius: 50%;
}

.page-chapter .navigation .arrows.active a {
  color: #d14b52;
}

.page-chapter .navigation .arrows.active a .round {
  background-color: #d14b52;
}

.KW_progressContainer {
  left: 0;
  width: 100%;
  height: 0.4em;
  margin-bottom: 0px;
  position: fixed;
  bottom: 0px;
  overflow: hidden;
  background-color: white;
  content: "";
  display: table;
  table-layout: fixed;
  z-index: 10;
}

.KW_progressBar {
  width: 0%;
  float: left;
  height: 100%;
  z-index: 99;
  max-width: 100%;
  background-color: orange;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.story-read-also {
  background-color: #f6f6f6;
}

progress {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  color: #d14b52;
  z-index: 100;
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

progress::-webkit-progress-value {
  background-color: #d14b52;
}

progress::-moz-progress-bar {
  background-color: #d14b52;
}

.home-slider {
  padding: 0;
}

.scroll-down {
  position: relative;
  margin: auto;
  text-align: center;
  top: -40px;
}

.scroll-down a {
  background-color: #ffffff;
  padding: 20px 35px;
  border-radius: 40%;
}

.home-info {
  text-align: center;
  padding-top: 45px;
}

.home-info .red-circle {
  position: relative;
  width: 90px;
  height: 90px;
  background-color: #d14b52;
  border-radius: 50%;
  padding: 25px;
  margin: auto;
  margin-bottom: 10px;
}

.home-info h2 {
  font-size: 20px;
  color: #323232;
}

.home-info .row {
  margin-bottom: 50px;
}

.home-info .see-rules {
  text-align: center;
}

.home-info .home-see-rules,
.home-info .home-paen .home-see-paen {
  background-color: #ffffff;
  color: #d14b52;
  border: 2px solid #d14b52;
  text-transform: uppercase;
}

.home-info .item {
  padding-bottom: 15px;
}

.home-stories {
  background-color: #f1e9dc;
  padding: 50px 0;
  text-align: center;
}

.home-stories h2 {
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
}

.home-stories h3 {
  font-size: 16px;
  color: #b47c5a;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.home-stories span {
  font-size: 16px;
  color: #333333;
  font-weight: 300;
  line-height: 30px;
}

.home-stories .brown-circle {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #b47c5a;
  border-radius: 50%;
  padding: 15px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;
}

.home-stories .see-stories {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-stories .home-see-stories {
  background-color: initial;
  color: #b47c5a;
  border: 2px solid #b47c5a;
  text-transform: uppercase;
}

.home-stories .story-stats-box span {
  position: absolute;
  top: -7px;
  left: 15px !important;
  font-size: 10px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.home-paen h2 {
  font-family: 'Poiret One', cursive;
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
}

.home-paen span {
  font-size: 16px;
  color: #333333;
  font-weight: 300;
  line-height: 30px;
}

.home-paen {
  padding: 50px 0;
  text-align: center;
}

.home-paen object {
  position: relative;
  margin: 50px 0 20px 0;
}

.home-paen .paen-box {
  height: 570px;
  padding: 0 20px 0 0;
  text-align: left;
}

.home-paen .paen-img {
  width: 100%;
  height: 260px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-paen .paen-img .paen-tag {
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  background-color: #d14b52;
  padding: 5px 10px;
  position: relative;
  left: 0;
  float: left;
  margin: 235px 0 0 0;
}

.home-paen .paen-box .paen-text {
  padding: 30px 0;
}

.home-paen .paen-box .paen-text .author {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
}

.home-paen .paen-box .paen-text .author a {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
}

.home-paen .paen-box .paen-text .resume {
  position: relative;
  padding: 20px 0 0 0;
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  color: #acacac;
  font-size: 17px;
}

.home-paen .paen-box .title a {
  font-size: 21px;
  color: #333333;
}

.home-paen .paen-box-big .paen-text {
  height: 250px;
  background-color: #f6f6f6;
  padding: 30px;
}

.home-paen .paen-box-big .paen-text .title a {
  font-size: 30px;
}

.home-paen .paen-box-big .paen-text .resume {
  color: #666666;
}

.carousel {
  height: 500px;
}

.carousel-caption {
  z-index: 10;
  position: relative;
  text-align: left;
  left: 0;
  right: 0;
  margin-top: 90px;
}

.carousel .item {
  height: 500px;
  background-color: #777;
}

.carousel-inner>.item>img {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100%;
  background-position: top;
}

.carousel-inner .item .background {
  position: absolute;
  width: 100%;
  height: 500px;
  background-color: #000000;
  opacity: 0.5;
}

.carousel-indicators {
  bottom: initial;
  text-align: left;
  padding-top: 60px;
  left: 34%;
}

.carousel-indicators .active {
  position: relative;
  background-color: initial;
  border: 4px solid #ffffff;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  top: -4px;
}

.carousel-indicators li {
  background-color: #b47c5a;
  border: 4px solid #b47c5a;
  border: none;
  margin-right: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.carousel h1 {
  font-family: 'Poiret One', cursive;
  font-size: 64px;
  color: #ffffff;
  text-shadow: none;
  text-transform: uppercase;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.carousel h2 {
  font-size: 14px;
  color: #eae7de;
  text-shadow: none;
  text-transform: uppercase;
  padding-top: 0;
  margin-top: 20px;
}

.carousel h2 object {
  margin-right: 10px;
}

.carousel .slider-button {
  background-color: #b47c5a;
  text-transform: uppercase;
  text-shadow: none;
}

.carousel .slider-button:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .navbar-wrapper {
    margin-top: 20px;
  }

  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-wrapper .navbar {
    border-radius: 4px;
  }

  .carousel-caption p {
    margin: 25px 0 50px 0;
    font-size: 15px;
    line-height: 25px;
    text-shadow: none;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 120px;
  }
}

.go-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  background-color: #d14b52;
  border-radius: 50%;
  text-align: center;
}

.go-top a {
  color: #ffffff;
}

.alert-reviews {
  color: #d14b52;
  padding: 5px;
}

.page-big-header-ho {
  height: 370px;
}

.page-big-header-ho .background {
  height: 370px;
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.page-big-header-ho .header {
  text-align: center;
  color: #ffffff;
  text-shadow: 3px 3px 5px black;
}

.page-big-header-ho .header h1 {
  padding: 0 0 15px 0;
  font-family: 'Poiret One', cursive;
  font-size: 72px;
  text-transform: uppercase;
}

.page-big-header-ho .header .subtitle {
  font-size: 19px;
}

.page-big-header-ho .header .picto {
  padding: 50px 0 15px 0;
  font-size: 40px;
}

.page-big-header-ho .custom-button {
  background-color: #db9534;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 45px;
  top: 30px;
}

.ho-intro-container {
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
}

.ho-intro-container .ho-intro .title {
  text-align: center;
}

.ho-intro-container .ho-intro .title .indicator {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #db9534;
  padding-top: 45px;
}

.ho-intro-container .ho-intro .title h2 {
  font-size: 45px;
  padding: 10px 0 15px 0;
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
}

.ho-intro-container .ho-intro .title .subtitle {
  font-size: 30px;
  font-weight: 500;
  padding: 15px 0;
  color: #2c2c2b;
}

.ho-intro-container .ho-intro .text {
  font-size: 16px;
  line-height: 30px;
  padding: 15px 0;
  column-count: 2;
  column-gap: 50px;
}

.ho-intro-container .ho-intro .text-phase2 {
  column-count: initial;
}

.ho-intro-container .ho-intro .custom-button {
  background-color: #db9534;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 45px;
}

.ho-intro-container .ho-intro .ho-menu .item {
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  width: 90%;
}

.ho-intro-container .ho-intro .ho-menu .item a {
  font-size: 14px;
  color: #666666;
}

.ho-intro-container .ho-intro .ho-menu .item a.active {
  font-weight: 600;
  color: #db9534;
}

.ho-intro-container .ho-progress {
  font-size: 18px;
  text-transform: uppercase;
  color: #db9534;
  font-weight: 500;
  padding: 30px;
}

.ho-intro-container .ho-progress .bar {
  position: relative;
  width: 80%;
  height: 4px;
  margin: auto;
  padding: 10px;
}

.ho-intro-container .ho-progress .bar .full {
  position: relative;
  height: 4px;
  float: left;
  background-color: #db9534;
}

.ho-intro-container .ho-progress .bar .empty {
  position: relative;
  height: 4px;
  float: left;
  background-color: #eae7de;
}

.ho-intro-container .ho-progress .info {
  color: #616161;
  text-transform: initial;
  font-size: 11px;
  text-align: center;
}

.ho-categs-container {
  background-color: #202022;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  padding: 30px 15px;
}

.ho-categs-container .ho-categs .title {
  text-align: center;
}

.ho-categs-container .ho-categs .title .indicator {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #db9534;
  padding-top: 45px;
}

.ho-categs-container .ho-categs .title h2 {
  font-size: 45px;
  padding: 10px 0 30px 0;
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
  color: #db9534;
}

.ho-categs-container .ho-categs .detail {
  padding: 15px;
  text-align: center;
}

.ho-categs-container .ho-categs .detail .picto {
  color: #db9534;
  font-size: 24px;
}

.ho-categs-container .ho-categs .detail .title {
  color: #db9534;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;
}

.ho-categs-container .ho-categs .detail .text {
  color: #ededed;
  font-size: 16px;
  line-height: 25px;
}

.ho-calendar {
  padding: 30px 15px;
}

.ho-calendar .title {
  text-align: center;
  background-image: url(/img/ho-zigouigoui.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 245px;
}

.ho-calendar .title .indicator {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #db9534;
  padding-top: 45px;
}

.ho-calendar .title h2 {
  font-size: 45px;
  padding: 10px 0 30px 0;
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
}

.ho-calendar .detail {
  padding: 15px 15px 25px 15px;
}

.ho-calendar .detail .title {
  color: #db9534;
  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;
  padding: 15px 15px;
  background-image: none;
  height: initial;
}

.ho-calendar .detail .title span {
  background-color: #ffffff;
  padding: 15px 30px;
}

.ho-calendar .detail .title:before {
  border-top: 2px solid #ededed;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  z-index: -1;
}

.ho-calendar .detail .dates {
  padding: 15px 30px;
  font-size: 16px;
  color: #020202;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 28px;
}

.ho-calendar .detail .dates .date {
  font-size: 42px;
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  color: #db9534;
  font-style: italic;
  width: 100%;
  text-transform: lowercase;
  float: left;
}

.ho-calendar .detail .text {
  padding: 15px 15px 0 15px;
  font-size: 15px;
  color: #202022;
  line-height: 30px;
}

.home-ho {
  background-color: #202022;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  padding: 30px 15px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.home-ho .content {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.home-ho .content h2 {
  font-size: 60px;
  padding: 45px 0 15px 0;
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
  color: #db9534;
}

.home-ho .content .subtitle {
  font-size: 30px;
  color: #ededed;
  font-weight: 500;
  padding: 0 15px 15px 0;
}

.home-ho .content .text {
  font-size: 16px;
  color: #ededed;
  line-height: 30px;
  padding: 0 15px 15px 0;
}

.home-ho .content .custom-button {
  background-color: #db9534;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 45px;
}

#modal-ho .modal-dialog {
  max-width: 900px;
}

#modal-ho .modal-dialog .modal-content {
  width: 900px;
}

#modal-ho .modal-dialog .modal-content .ho {
  text-align: left;
}

#modal-ho .modal-dialog .modal-content .ho .indicator {
  text-align: center;
  font-size: 14px;
  color: #db9534;
  text-transform: uppercase;
  font-weight: 500;
}

#modal-ho .modal-dialog .modal-content .ho h2 {
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 400;
}

#modal-ho .modal-dialog .modal-content .ho .subtext-left {
  text-align: left;
}

#modal-ho .modal-dialog .modal-content .ho .subtext-left .length {
  font-size: 12px;
  color: #666;
  font-style: italic;
  padding: 0 0 20px 0;
}

#modal-ho .modal-dialog .modal-content .ho .subtext-left .error {
  display: none;
}

.notifs-show {
  position: fixed;
  bottom: 85px;
  right: 15px;
  width: 500px;
  display: none;
}

.notifs-show .account-info-box {
  box-shadow: 0 3px 8px rgba(154,153,153,0.5) !important;
}

@media screen and (max-width: 992px) {
  .notifs-show {
    display: none !important;
  }
}

.display-none {
  display: none;
}

.account-image {
  width: 80px;
  height: 80px;
  background-color: #d14b52;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.account-red {
  background-color: #d14b52 !important;
  color: #ffffff !important;
}

.account-red .title {
  color: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
}

.account-red label {
  color: #ffffff !important;
}

.account-red a {
  color: #ffffff !important;
  border-bottom: 1px dotted #ffffff !important;
}

.account-red .parentAuthorizationFile {
  border: 1px solid #ffffff;
  padding: 10px 30px;
  margin: 15px 0;
  background-color: #ffffff;
  color: #d14b52;
  border-radius: 5px;
  opacity: 0.8;
}

.account-red .parentAuthorizationFile i {
  margin-right: 10px;
}

.account-red .parentAuthorizationFile a {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  border-bottom: none !important;
  color: #d14b52 !important;
}

.account-red .parentAuthorizationFile .delete {
  float: right;
}

.account-red .small {
  text-transform: initial !important;
  font-weight: 400 !important;
}

.account-red .file {
  padding-top: 15px;
  text-align: center;
  font-size: 60px;
}

.account-red .red-button {
  background-color: #ffffff;
  color: #d14b52;
}

.account-red a.red-button {
  color: #d14b52 !important;
  font-size: 16px;
  font-weight: 600;
}

.account-red .info {
  color: #ffffff !important;
}

.home-dixit {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  padding: 30px 15px;
  border-top: 2px solid #d14b52;
  border-bottom: 2px solid #d14b52;
  background: linear-gradient(45deg, #d14b52 30%, #d14b52 30%, transparent 30%),linear-gradient(45deg, transparent 75%, #d14b52 75%, #d14b52 100%);
  mix-blend-mode: darken;
}

.home-dixit .content h2 {
  font-size: 60px;
  padding: 45px 0 15px 0;
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
  mix-blend-mode: screen;
  color: #d14b52;
}

.home-dixit .content .subtitle {
  font-size: 30px;
  font-weight: 500;
  padding: 0 15px 15px 0;
  mix-blend-mode: screen;
}

.home-dixit .content .text {
  font-size: 16px;
  line-height: 30px;
  padding: 0 15px 15px 0;
  mix-blend-mode: screen;
}

.home-dixit .content .custom-button {
  background-color: #d14b52;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 45px;
  width: 250px;
  float: right;
  mix-blend-mode: screen;
}

.home-dixit .content .image-left {
  padding-top: 45px;
}

.home-dixit .content .image-left .image {
  width: 300px;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border: 2px solid #d14b52;
}

.dixit .thumbs {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 576px) {
  .dixit .thumbs {
    padding: 20px 20px;
  }
}

.dixit .thumbs .box {
  float: left;
  width: 43px;
  text-align: center;
  font-size: 15px;
  padding: 10px 15px;
}

.dixit .thumbs .box a {
  color: #b5b5b5;
}

.dixit .thumbs .box.active a {
  color: #ffffff;
}

.dixit .thumbs .box.active {
  background-color: #b47c5a;
  border-radius: 50%;
  width: 42px;
}

@media screen and (max-width: 1200px) {
  .dixit .thumbs .box.active {
    width: 42px;
  }
}

.dixit-intro-container {
  mix-blend-mode: darken;
}

.dixit-intro-container h3 {
  padding: 15px 0 0 0;
  font-family: 'Poiret One', cursive;
  font-size: 30px;
  text-transform: uppercase;
}

.dixit-intro-container .ho-intro {
  mix-blend-mode: screen;
}

.dixit-intro-container .ho-intro .title .indicator {
  color: #d14b52;
}

.dixit-intro-container .ho-intro .custom-button {
  background-color: #d14b52;
}

.dixit-intro-container .ho-intro .ho-menu .item a.active {
  color: #d14b52;
}

.dixit-intro-container .ho-intro .images {
  padding: 15px;
}

.dixit-intro-container .ho-intro .images .item {
  text-align: center;
  padding: 15px;
}

.dixit-intro-container .ho-intro .images .item .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border: 2px solid #d14b52;
  margin: auto;
}

.dixit-intro-container .ho-intro .images .item .image-first {
  width: 300px;
  height: 400px;
}

.dixit-intro-container .ho-intro .images .item .image-second {
  width: 200px;
  height: 266px;
}

.dixit-intro-container .ho-intro .images .item .week {
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #d14b52;
}

.dixit-intro-container .ho-intro .images .item .title {
  font-weight: 500;
}

.dixit-intro-container .ho-intro .images .week2 {
  opacity: 0.8;
}

.dixit-intro-container .ho-intro .images .week3 {
  opacity: 0.65;
}

.dixit-intro-container .ho-intro .images .week4 {
  opacity: 0.5;
}

.dixit-intro-container .ho-intro .infos {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  padding: 15px;
}

.histoiresor_participation_form .hide {
  display: none;
}

.histoiresor_participation_form .histoire_or_error {
  padding: 15px;
  text-align: center;
  color: #d14b52;
  font-size: 16px;
}

.histoiresor_participation_form .working {
  padding: 30px;
  text-align: center;
  font-size: 20px;
  color: #db9534;
}

.histoiresor_participation_form .success {
  text-align: center;
}

.histoiresor_participation_form .success span {
  color: #db9534;
  font-weight: 600;
}

.ho-phase2 {
  padding: 30px;
}

.ho-phase2 .categories .title {
  padding: 15px 15px 30px 15px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #db9534;
  text-align: center;
}

.ho-phase2 .categories .item {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
}

.ho-phase2 .categories .item a {
  font-weight: 500;
}

.ho-phase2 .stories .category {
  padding: 0 15px 30px 15px;
}

.ho-phase2 .stories .category .title {
  font-family: 'Poiret One', cursive;
  font-size: 37px;
  color: #db9534;
}

.ho-phase2 .stories .category .info {
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 15px;
}

.ho-phase2 .stories .category .ho-progress {
  font-size: 16px;
  text-transform: uppercase;
  color: #db9534;
  font-weight: 500;
  padding: 10px 0;
}

.ho-phase2 .stories .category .ho-progress .text {
  font-size: 14px;
  float: left;
}

.ho-phase2 .stories .category .ho-progress .bar {
  position: relative;
  width: 68%;
  height: 4px;
  padding: 10px;
  float: left;
}

.ho-phase2 .stories .category .ho-progress .bar .full {
  position: relative;
  height: 4px;
  float: left;
  background-color: #db9534;
}

.ho-phase2 .stories .category .ho-progress .bar .empty {
  position: relative;
  height: 4px;
  float: left;
  background-color: #eae7de;
}

.ho-phase2 .stories .category .ho-progress .percent {
  float: left;
  font-size: 14px;
  font-weight: 500;
}

.ho-phase2 .stories .category .stories .box-story {
  height: 390px;
}

.ho-phase2 .stories .category .stories .box-story .story-title {
  border-bottom: none;
  height: initial;
}

.ho-phase2 .stories .category .stories .box-story .story-title span {
  font-size: 20px;
  line-height: 18px;
}

.ho-phase2 .stories .category .stories .box-story .story-img {
  height: 250px;
}

.ho-phase2 .stories .category .stories .box-story .story-img .lu {
  color: #db9534;
  background-color: #ffffff;
  padding: 3px 8px;
  border-bottom-right-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  width: 52px;
}

.ho-phase2 .stories .category .stories .box-story .story-img .vote {
  position: absolute;
  text-align: center;
  top: 120px;
  width: 100%;
}

.ho-phase2 .stories .category .stories .box-story .story-img .vote .custom-button {
  color: #ffffff;
  background-color: #db9534;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.ho-phase2 .stories .category .stories .box-story .story-img .a-vote {
  position: absolute;
  width: 98%;
  height: 250px;
  background-color: #db9534;
  opacity: 0.75;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 120px;
}

.ho-phase2 .stories .category .stories .box-story .details {
  padding: 15px;
  font-size: 14px;
  text-transform: uppercase;
  color: #db9534;
  font-weight: 600;
}

.ho-phase2 .stories .category .stories .box-story .details a {
  color: #db9534;
}

.modal-ho .modal-dialog {
  max-width: 900px !important;
}

.modal-ho .modal-dialog .modal-content {
  width: 900px !important;
  padding: 30px;
}

.modal-ho .modal-dialog .modal-content .modal-body {
  padding: 45px 30px !important;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-img {
  width: 100%;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-ho .modal-dialog .modal-content .modal-body .categories .title {
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
  line-height: 20px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  padding: 15px 15px 5px 15px;
}

.modal-ho .modal-dialog .modal-content .modal-body .categories .item {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #db9534;
  padding: 10px;
}

.modal-ho .modal-dialog .modal-content .modal-body .category-name {
  background-color: #f3eacf;
  color: #db9534;
  padding: 5px 10px;
  margin-bottom: 5px;
  width: fit-content;
  font-weight: 600;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-title {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 55px;
  font-weight: 500;
  color: #db9534;
  text-transform: none;
  font-size: 36px;
  line-height: 34px;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-title i {
  font-size: 20px;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-title a {
  color: #db9534;
  border-bottom: none;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-author {
  color: #db9534;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
}

.modal-ho .modal-dialog .modal-content .modal-body .story-author a {
  color: #db9534;
  border-bottom: 1px solid #db9534;
}

.modal-ho .modal-dialog .modal-content .modal-body .quote {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 18px;
  line-height: 24px;
  padding: 30px 0 20px 0;
  border-bottom: 1px solid #eae7de;
}

.modal-ho .modal-dialog .modal-content .modal-body .quote:first-letter {
  font-size: 60px;
  color: #db9534;
  font-weight: 600;
}

.modal-ho .modal-dialog .modal-content .modal-body .explanation {
  padding: 20px 0 0 0;
  line-height: 24px;
}

.modal-ho .modal-dialog .modal-content .modal-body .explanation strong {
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 5px;
}

.modal-ho-vote .confirm {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  padding: 0 15px 30px 15px;
  font-size: 14px;
}

.modal-ho-vote .confirm span {
  color: #db9534;
  font-size: 14px;
}

.modal-ho-vote .buttons {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.modal-ho-vote .buttons .accept {
  background-color: #db9534;
  color: #ffffff !important;
  border: none !important;
}

.modal-ho-vote .buttons .refuse {
  background-color: #eae7de;
  color: #616161 !important;
  border: none !important;
}

.modal-ho-vote .info {
  color: #616161;
  text-transform: none;
  font-size: 13px !important;
  text-align: center;
  padding-top: 30px;
}

.modal-delete-account .confirm {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  padding: 0 15px 30px 15px;
  font-size: 14px;
}

.modal-delete-account .buttons {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.modal-delete-account .buttons .accept {
  background-color: #d14b52;
  color: #ffffff !important;
  border: none !important;
}

.modal-delete-account .buttons .refuse {
  background-color: #eae7de;
  color: #616161 !important;
  border: none !important;
}

.modal-delete-account .info {
  color: #616161;
  text-transform: none;
  font-size: 13px !important;
  text-align: center;
  padding-top: 30px;
}

.first-category .story-img {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.first-category .story-title {
  color: #db9534;
}

.first-category .story-title a {
  color: #db9534;
}

.first-category .story-title span {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
}

.first-category .quote {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 18px;
  line-height: 24px;
  padding: 30px 0 20px 0;
  height: 330px;
  overflow: hidden;
}

.first-category .quote:first-letter {
  font-size: 60px;
  color: #db9534;
  font-weight: 600;
}

.first-category .quote:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), #fff 90%);
  width: 100%;
  height: 200px;
}

.ho-categs-all h2 {
  color: #ffffff !important;
}

.ho-categs-all .all-categories .item {
  height: 700px;
}

.ho-categs-all .all-categories .item .category {
  font-family: 'Poiret One', cursive;
  font-size: 37px;
  color: #db9534;
  text-align: center;
  opacity: 0.8;
  height: 100px;
}

.ho-categs-all .all-categories .item .story-img {
  width: 65%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  opacity: 0.7;
}

.ho-categs-all .all-categories .item .story-title {
  color: #ffffff;
  opacity: 0.7;
  text-align: center;
  width: 70%;
  margin: auto;
}

.ho-categs-all .all-categories .item .story-title a {
  color: #ffffff;
}

.ho-categs-all .all-categories .item .story-title span {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
}

.ho-categs-all .all-categories .item .story-title .character {
  font-size: 18px;
}

.admin-moderation-rapide {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
  margin: 10px 0;
  border: 2px solid #db9534;
}

.admin-moderation-rapide a {
  color: #db9534;
}

.admin-moderation-rapide a i {
  font-size: 22px;
}

#modal-moderation-rapide .modal-dialog {
  max-width: 900px;
}

#modal-moderation-rapide .modal-dialog .modal-content {
  width: 900px;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body {
  text-align: left;
  background-color: #db9534;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body h2 {
  font-family: 'Poiret One', cursive;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 400;
  color: #ffffff;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext {
  color: #ffffff;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext select {
  width: 100%;
  padding: 10px;
  opacity: 0.8;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  opacity: 0.8;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext .message {
  background-color: #ffffff;
  color: #616161;
  padding: 15px;
  text-align: left;
  margin-top: 5px;
  border-radius: 15px;
  opacity: 0.8;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext .info {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

#modal-moderation-rapide .modal-dialog .modal-content .modal-body .subtext .custom-button {
  border: 0px;
  background-color: #ffffff;
  color: #db9534;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  opacity: 0.9;
}

.cover-warning {
  padding-top: 30px !important;
  text-align: center;
}

.cover-warning i {
  font-size: 50px;
  color: #d14b52;
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

.home-actus {
  background-color: #d14b52;
  padding: 30px 15px;
}

.home-actus h2 {
  color: #ffffff;
  padding-bottom: 15px;
}

.home-actus .box-story .story-genres {
  padding: 0;
  height: 20px;
}

.home-actus .box-story .story-genres .story-genres-box {
  display: none;
}

.home-actus .box-story .story-title {
  border-bottom: none;
}

.home-actus .box-story .story-title a {
  color: #ffffff;
  opacity: 0.9;
}

.home-actus .box-story .story-title p {
  color: #ffffff;
  opacity: 0.7;
}

.calendar hr {
  border-top-color: #d14b52;
}

.calendar .title {
  padding: 30px 15px 15px 15px;
}

.calendar .title .text {
  position: absolute;
  top: 20px;
}

.calendar .title .text h2 {
  font-size: 40px;
  color: #d14b52;
  background-color: #ffffff;
  text-transform: uppercase;
  padding-right: 20px;
}

.calendar .month .day {
  float: left;
  width: 14%;
  height: 150px;
  border: 1px solid #707070;
  margin: 0 2px 2px 0;
}

.calendar .month .day .date {
  font-weight: 600;
  color: #707070;
  padding: 5px 15px;
  width: 30%;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
}

.calendar .month .day-none {
  opacity: 0.5;
}

.calendar .month .active {
  background-color: #e0e0e0;
}

.calendar .month .active .date {
  background-color: #707070;
  color: #ffffff;
  border: none;
}

.calendar .month .event {
  position: absolute;
  padding: 15px;
  width: 99%;
  color: #ffffff;
  margin-left: 2px;
  border-radius: 50px;
  text-align: center;
  opacity: 0.9;
}

.calendar .month .event a {
  color: #ffffff;
  border-bottom: 1px dotted #ffffff;
}

.calendar .month .w-1 {
  width: 13.6%;
}

.calendar .month .w-2 {
  width: 27.8%;
}

.calendar .month .w-3 {
  width: 42%;
}

.calendar .month .w-4 {
  width: 56.2%;
}

.calendar .month .w-5 {
  width: 70.3%;
}

.calendar .month .w-6 {
  width: 84.5%;
}

.calendar .month .w-7 {
  width: 98.7%;
}

.calendar .month .start-2 {
  left: 14.2%;
}

.calendar .month .start-3 {
  left: 28.3%;
}

.calendar .month .start-4 {
  left: 42.45%;
}

.calendar .month .start-5 {
  left: 56.6%;
}

.calendar .month .start-6 {
  left: 70.8%;
}

.calendar .month .start-7 {
  left: 85%;
}

.calendar .month .color-yellow {
  background-color: #db9534;
}

.calendar .month .color-red {
  background-color: #d14b52;
}

.calendar .month .color-green {
  background-color: #23a44e;
}

.calendar .month .color-brown {
  background-color: #b47c5a;
}

.calendar .month .line-1 {
  top: 40px;
}

.calendar .month .line-2 {
  top: 95px;
}

.home-calendar {
  background-color: #333333;
}

.home-calendar h2 {
  color: #ffffff;
  padding-bottom: 15px;
}

.home-calendar .month .day {
  border-color: #ffffff;
}

.home-calendar .month .day .date {
  border-color: #ffffff;
  color: #ffffff;
}

.home-calendar .month .active {
  background-color: #707070;
}

.home-calendar .month .active .date {
  background-color: #333333;
}

.home-calendar .see-calendar {
  text-align: center;
  margin-top: 50px;
}

.home-calendar .see-calendar .custom-button {
  background-color: initial;
  color: #ffffff;
  border: 2px solid #ffffff;
  text-transform: uppercase;
}

.parathon-big-header .header {
  text-shadow: 3px 3px 20px black;
}

.parathon-big-header .header h1 {
  font-weight: 600;
}

.parathon-intro-container .intro .title .indicator {
  color: #23a44e;
}

.parathon-intro-container .intro button .custom-button {
  background-color: #23a44e;
  border: none;
}

.parathon-intro-container .intro button .custom-button:disabled {
  background-color: #707070;
  cursor: not-allowed;
}

.parathon-intro-container .inscription .title .indicator {
  color: #23a44e;
}

.parathon-intro-container .inscription .title h2 {
  color: #23a44e;
  font-weight: 600;
}

.parathon-intro-container .inscription .texte {
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  padding: 15px 0;
  column-count: 2;
  column-gap: 50px;
}

.parathon-intro-container .inscription .formulaire .items {
  padding: 30px 0 0 0;
}

.parathon-intro-container .inscription .formulaire .items .item {
  margin-bottom: 15px;
}

.parathon-intro-container .inscription .formulaire .items .item .element {
  font-family: "Crimson Text script=all rev=2", "Adobe Blank";
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
  color: #202022;
  width: 100%;
  text-align: center;
  padding: 30px 15px;
  background-color: #ffffff;
  cursor: pointer;
  border: 3px solid #23a44e;
  line-height: 25px;
}

.parathon-intro-container .inscription .formulaire .items .item .element span {
  font-size: 42px;
  font-weight: 600;
  font-style: normal;
  color: #23a44e;
}

.parathon-intro-container .inscription .formulaire .items .active .element {
  border-color: #ffffff;
  background-color: #23a44e;
}

.parathon-intro-container .inscription .formulaire .items .active .element span {
  color: #ffffff;
}

.parathon-intro-container .inscription .formulaire .custom-button {
  margin-top: 15px;
  background-color: #23a44e;
  text-transform: uppercase;
  font-weight: 600;
}

.parathon-intro-container .inscription .formulaire .custom-button-off {
  background-color: #707070;
  margin-right: 5px;
  font-weight: 500;
}

.parathon-intro-container .title .indicator {
  color: #23a44e;
}

.parathon-intro-container .title h2 {
  color: #23a44e;
  font-weight: 600;
}

.parathon-intro-container .detail .title {
  color: #23a44e;
}

.parathon-intro-container .detail .dates .date {
  color: #23a44e;
}

.parathon-intro-container .compteur-total {
  text-align: center;
  color: #23a44e;
  padding: 30px 0 15px 0;
  font-family: 'Poiret One', cursive;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 600;
}

.parathon-intro-container .bloc-compteur {
  margin-top: 15px;
  padding: 30px;
  border-radius: 10px;
  color: #23a44e;
  border: 2px solid #23a44e;
  text-align: center;
  height: 300px;
}

.parathon-intro-container .bloc-compteur h2 {
  font-family: 'Poiret One', cursive;
  font-weight: 600;
}

.parathon-intro-container .bloc-compteur input[type="text"] {
  width: 80%;
  margin: 0;
  padding: 15px 35px;
  border-radius: 30px;
  color: #5a5a5a;
  border: 2px solid #23a44e;
}

.parathon-intro-container .bloc-compteur .custom-button {
  width: 80%;
  margin: auto;
  margin-top: 15px;
  background-color: #23a44e;
}

.parathon-intro-container .bloc-compteur .custom-button a {
  color: #ffffff;
  font-weight: 600;
}

.parathon-intro-container .bloc-compteur .buttons .left {
  float: left;
  width: 50%;
}

.parathon-intro-container .bloc-compteur .buttons .left input {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 13px 35px;
  text-align: center;
}

.parathon-intro-container .bloc-compteur .buttons .right {
  float: left;
  width: 50%;
  margin-top: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.parathon-intro-container .bloc-trucopoints {
  margin-top: 15px;
  padding: 30px;
  border-radius: 10px;
  background-color: #23a44e;
  color: #ffffff;
  text-align: center;
  height: 300px;
}

.parathon-intro-container .bloc-trucopoints h2 {
  font-family: 'Poiret One', cursive;
  font-weight: 600;
}

.parathon-intro-container .bloc-trucopoints .grille {
  margin-top: 30px;
}

.parathon-intro-container .bloc-trucopoints .grille .item {
  position: relative;
  width: 20%;
  float: left;
  padding: 15px 0;
  height: 75px;
  font-size: 30px;
}

.parathon-intro-container .bloc-classement {
  margin-top: 30px;
  padding: 0;
  border: 1px solid #23a44e;
  border-radius: 15px;
}

.parathon-intro-container .bloc-classement .entete {
  background-color: #23a44e;
  color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  font-size: 20px;
}

.parathon-intro-container .bloc-classement .entete .picto {
  float: left;
  padding-right: 15px;
  font-size: 30px;
  top: -10px;
  position: relative;
  color: #db9534;
}

.parathon-intro-container .bloc-classement .utilisateur {
  padding: 10px 30px;
}

.parathon-intro-container .bloc-classement .utilisateur .pseudo {
  font-weight: 600;
  color: #23a44e;
}

.parathon-intro-container .bloc-classement .utilisateur .image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  padding-top: 10px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  text-shadow: 0 0 10px #000000;
}

.parathon-intro-container .bloc-titre {
  padding: 30px 15px 0 15px;
}

.parathon-intro-container .bloc-titre h3 {
  font-family: 'Poiret One', cursive;
  font-size: 36px;
}

.espace-discussion .bloc-discussion {
  border-top: 1px solid #666666;
  border-right: 1px solid #666666;
  border-top-right-radius: 15px;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.espace-discussion .bloc-discussion .titre {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.espace-discussion .bloc-discussion .icon-read {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 26px;
  text-align: center;
  width: 50px;
  height: 50px;
  padding-top: 5px;
}

.espace-discussion .bloc-discussion .new {
  color: #ffffff;
  background-color: #666666;
  border-top-right-radius: 14px;
  border-bottom-left-radius: 15px;
}

.espace-discussion .bloc-discussion .texte {
  padding-top: 15px;
}

.espace-discussion .bloc-discussion .texte .image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
}

.espace-discussion .bloc-discussion .reponses {
  padding-top: 10px;
  font-size: 13px;
}

.espace-discussion .bloc-discussion .reponses .image {
  width: 50px;
  height: 50px;
}

.espace-discussion .bloc-discussion .reponses .pictos {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #cecece;
  text-align: center;
}

.espace-discussion .bloc-discussion .reponses .pictos i {
  font-size: 30px;
}

.espace-discussion .bloc-discussion .reponses .pictos span {
  position: absolute;
  top: -5px;
  left: 55px !important;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.espace-discussion .bloc-discussion .points {
  text-align: center;
  font-size: 22px;
}

.espace-discussion .bloc-discussion-detail {
  border-bottom: 1px solid #b5b5b5;
}

.espace-discussion .bloc-discussion-detail .entete {
  position: relative;
  min-height: 175px;
  margin-bottom: 20px;
}

.espace-discussion .bloc-discussion-detail .entete .separateur {
  margin: auto;
  width: 50%;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 30px;
}

.espace-discussion .bloc-discussion-detail .entete h2 {
  font-family: 'Montserrat', sans-serif;
  color: #616161;
  font-size: 30px;
}

.espace-discussion .bloc-discussion-detail .entete .utilisateur {
  padding: 10px 30px;
}

.espace-discussion .bloc-discussion-detail .entete .utilisateur .pseudo {
  font-weight: 600;
  color: #23a44e;
}

.espace-discussion .bloc-discussion-detail .entete .utilisateur .image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin: 0;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  padding-top: 10px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  text-shadow: 0 0 10px #000000;
}

.espace-discussion .bloc-discussion-detail .entete .utilisateur .date {
  color: grey;
  font-size: 11px;
  font-weight: 500;
  padding: 15px 0 0;
  float: left;
  top: -25px;
  position: relative;
}

.espace-discussion .bloc-discussion-detail .corps .like {
  position: relative;
  text-align: center;
}

.espace-discussion .bloc-discussion-detail .corps .like i {
  font-size: 45px;
  color: #666666;
}

.espace-discussion .bloc-discussion-detail .corps .like span {
  position: relative;
  top: -25px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 0 3px;
  line-height: initial;
}

.espace-discussion .bloc-discussion-detail .corps .actif i {
  color: #d14b52;
}

.espace-discussion .bloc-discussion-detail-reponse {
  padding-top: 25px;
}

.espace-discussion .bloc-discussion-detail-reponse .entete {
  min-height: 100px;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire {
  border-bottom: none;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item {
  margin: 30px 0;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item .comment-title {
  font-size: 18px;
  color: #2c2c2b;
  font-weight: 500;
  padding: 20px 0 20px 0;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item .comment-title span {
  color: #b47c5a;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item textarea {
  border: 2px solid #f1e9dc;
  color: #b47c5a;
  font-size: 14px;
  padding: 15px;
  width: 100%;
  height: 130px;
  resize: vertical;
  line-height: 20px;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item .comment-submit {
  margin: 10px 0;
}

.espace-discussion .bloc-discussion-detail-reponse-formulaire .single-item .comment-submit input[type="submit"] {
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 35px;
  background-color: #23a44e;
}

