@import "~bootstrap/scss/bootstrap";

$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$break-xlarge: 1200px;

body {
    font-family: 'Montserrat', sans-serif;
    color: #616161;
    font-size: 14px;
}

.hidden {
    display: none;
}

#pjAcceptCookieBar {
    background-color: #d14b52 !important;
    box-shadow: 0 -5px 20px #666666;

    a {
        color: #ffffff;
        border-bottom: 1px dotted;

        &:hover {
            color: #ffffff;
            border-bottom: 1px dotted;
        }
    }
    .pjAcceptCookieBarBtn {
        background-color: #ffffff !important;
        color: #d14b52 !important;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 30px !important;
        margin-top: 10px !important;
    }
}
a {
    color: #666666;

    &:hover {
        text-decoration: none;
        color: #333333;

        &.svg {
            position: relative;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left:0;
            }
        }
    }
}
a.svg {
    position: relative;
    display: inline-block;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left:0;
    }
}
.clear-both {
    clear: both;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}
.header {
    padding-top: 20px;

    .custom-button {
        width: 100%;
        background-color: #d14b52;
        color: #ffffff;
        padding: 15px 35px;
        border-radius: 30px;
        text-align: center;

        a {
            color: #ffffff;

            object {
                position: relative;
                float: left;
                height: 30px;
                top: -5px;
            }
        }
    }
    .copyright {
        a {
            color: #2c2c2b;
        }
    }
}
.loader {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    .picto {
        position: absolute;
        top: 50%;
        left: 50%;

        i {
            color: #d14b52;
            font-size: 30px;
        }
    }
}
.custom-button {
    width: 100%;
    background-color: #d14b52;
    color: #ffffff;
    padding: 15px 35px;
    border-radius: 30px;
    text-align: center;

    a {
        color: #ffffff;

        object {
            position: relative;
            float: left;
            height: 30px;
            top: -5px;
        }
    }
}
.menu {
    background-color: #b47c5a;
    margin: 20px 0 0 0;
    padding: 15px 0;
    height: 50px;

    .item {
        float: left;
        padding: 0 20px;
        text-transform: uppercase;

        &:first-of-type {
            padding-left: 0;
        }

        a {
            color: #ffffff;
        }
    }

    .item-extern {
        font-size: 13px;
        float: right;

        a {
            opacity: 0.7;

            object {
                position: relative;
                top: 3px;
                margin: 0 8px 0 0;
            }
        }
    }
}
.menu-top {
    background-color: #f0f0f0;
    margin: 0;
    padding: 10px 0;
    height: 40px;

    a {
        font-size: 13px;
        color: #707070;

        i {
            color: #4e4e4e;
            padding-right: 7px;
        }
    }

    .item {
        float: left;
        padding: 0 20px;
        text-transform: uppercase;
        font-weight: 500;
    }
    .item-first {
        padding-left: 0;
        border-right: 1px solid #c6c6c6;
    }
    .item-extern {
        font-size: 13px;
        float: right;
        text-transform: none;
    }
    .item-last {
        padding-right: 0;
    }
}

.footer-newsletter {
    background-image: url(/img/FD_newsletter.svg);
    background-position: center;
    background-size: cover;
    background-color: #b47c5a;
    margin: 20px 0 0 0;
    padding: 40px 0;
    color: #ffffff;
    text-align: center;

    h2 {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 200;
        padding-bottom: 0;
    }

    h3 {
        font-size: 16px;
        font-weight: 300;
        padding: 0 0 20px 0;
    }

    input[type="text"] {
        width: 80%;
        margin: 0;
        float: left;
        padding: 15px 35px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border: none;
        color: #5a5a5a;
    }

    input[type="submit"] {
        width: 20%;
        margin: 0;
        float: left;
        padding: 15px 0;
        text-align: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: none;
        background-color: #eae7de;
        font-size: 14px;
        color: #333333;
        text-transform: uppercase;
    }

    .custom-button {
        background-color: #eae7de;
        color: #333333;
        text-transform: uppercase;
        font-size: 14px;
        padding: 15px 30px;
        font-weight: 500;
    }
}
.footer-links {
    background-color: #eae7de;
    margin: 0;
    padding: 40px 0;

    .header {
        font-size: 16px;
        color: #666666;
        text-transform: uppercase;
        font-weight: 600;
    }

    span {
        font-weight: 300;
    }

    .row {
        margin-bottom: 20px;
        line-height: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: #666666;
        font-weight: 300;

        .link-bold {
            font-weight: 400;
        }
    }

    object {
        position: relative;
        margin-right: 20px;
    }
    .social-links {
        padding: 20px 15px;

        a {
            color: #b47c5a;
            padding-right: 15px;

            i {
                font-size: 20px;
            }
        }
    }
}
.footer-links2 {
    padding: 30px 0;
    color: #acacac;
    font-size: 13px;
    font-weight: 300;

    a {
        color: #acacac;
        font-weight: 400;
    }

    .align-right {
        text-align: right;
    }
}

.page-infos {
    text-align: center;

    h1 {
        padding: 50px 0 15px 0;
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
    }
    h2 {
        padding: 50px 0 15px 0;
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
    }
    h3 {
        padding: 50px 0 15px 0;
        font-family: 'Poiret One', cursive;
        font-size: 40px;
        color: #333333;
        text-transform: uppercase;
    }
    object {
        position: relative;
        padding: 30px 0;
    }
    span{
        font-size: 16px;
        color: #333333;
        font-weight: 300;
        line-height: 30px;
    }
    .participate {
        padding: 45px 15px;

        a.custom-button {
            text-transform: uppercase;
            color: #ffffff;
        }
    }
    .custom-button {
        border: none;
        text-transform: uppercase;
    }

    .story-stats-box {
        span {
            position: absolute;
            top: -7px;
            left: 15px !important;
            font-size: 10px;
            font-weight: 600;
            background-color: #ffffff;
            border: 1px solid #666666;
            border-radius: 5px;
            padding: 0 3px;
            line-height: initial;
        }
    }
}
.page-contest {
    .story-stats-box {
        span {
            position: absolute;
            top: -7px;
            left: 24px !important;
            font-size: 10px;
            font-weight: 600;
            background-color: #ffffff;
            border: 1px solid #666666;
            border-radius: 5px;
            padding: 0 3px;
            line-height: initial;
        }
    }
}
.breadcrumbs {
    padding: 15px 0 15px 40px;
    border-bottom: 1px solid #eaeaea;
    font-size: 13px;
    color: #666666;
    text-align: left;

    a {
        color: #acacac;
    }
}
.connect-register {
    padding: 30px 15px;
    font-size: 15px;
    color: #616161;
    line-height: 25px;

    h2 {
        font-size: 35px;
        color: #616161;
        font-weight: 300;
        margin-bottom: 20px;
    }
    span {
        font-weight: 300;
    }
    .form-content {
        padding: 20px 15px;

        form {
            width: 100%;
        }

        label {
            font-size: 12px;
            color: #b47c5a;
            font-weight: 500;
            text-transform: uppercase;
        }
        input[type="text"], input[type="password"], input[type="email"] {
            width: 100%;
            border: 2px solid #f1e9dc;
            font-size: 14px;
            color: #b47c5a;
            padding: 10px;
        }
        .form-box {
            padding: 0 0 10px 0;
        }
        button {
            background-color: #b47c5a;
            border: none;
            text-transform: uppercase;
        }
        a {
            font-size: 14px;
            color: #616161;
            border-bottom: 1px solid #616161;
        }
        .lost-password {
            padding: 15px;
        }
        ul {
            font-size: 12px;
            color: #e86a6a;
            font-style: italic;
            font-weight: 400;
            list-style: none;
            padding: 0 0 0 15px;
            margin: 0;
            width: -webkit-fill-available;
        }
        .error {
            label {
                color: #e86a6a;
            }
            input {
                border-color: #e86a6a;
                color: #e86a6a;
            }
            span {
                display: block;
            }
        }
    }
    .success {
        color: #616161;
        text-align: center;
        padding: 0 0 30px 0;
    }
    .hidden {
        display: none;
    }

}
.connected-infos {
    padding: 15px 0 0 0;
    text-align: center;

    .header-superadmin {
        padding: 10px 0 0 0;

        a {
            background-color: #b5b5b5;
            color: #ffffff;
            font-size: 12px;
            text-transform: uppercase;
            break-inside: avoid;
            padding: 15px 13px;
        }
    }
    .header-login {
        padding: 0;

        a {
            color: #d14b52;
            font-size: 14px;
            font-weight: 600;
            float: right;
            padding: 10px 0 0 0
        }
        .header-image-login {
            width: 40px;
            height: 40px;
            background-color: #d14b52;
            border-radius: 50%;
            float: right;
            margin: 0 5px 0 0;
            background-position: center;
            background-size: cover;
        }
    }
    .header-pal, .header-alert {
        padding: 10px 0 0 0;

        a {
            color: #616161;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 0 0 0;
        }
        .header-pal-nb, .header-alert-nb {
            position: absolute;
            top: 0;
            right: 15px;
            background-color: #e5e5e5;
            color: #d14b52;
            font-size: 11px;
            font-weight: 600;
            padding: 3px 7px;
            border-radius: 8px;
        }
        .header-alert-nb {
            background-color: #d14b52;
            color: #ffffff;
            right: 0;
        }
    }
}
.modal {
    .modal-dialog {
        max-width: 600px;
        margin-top: 45px;

        @media screen and (max-width: $break-large) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-top: 45px;
        }
        .modal-content {
            background-color: transparent;
            border: none;
            box-shadow: none;
            width: 600px;

            @media screen and (max-width: $break-large) {
                width: 100%;
            }

            a {
                color: #616161;
                border-bottom: 1px solid #616161;
            }

            .modal-header {
                border: none;

                .close {
                    color: #ffffff;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
            .modal-body {
                background-color: #ffffff;
                padding: 30px 45px;

                h2 {
                    color: #323232;
                    font-size: 30px;
                    text-align: center;
                    font-weight: 600;
                }
                .subtext {
                    color: #616161;
                    font-size: 15px;
                    text-align: center;
                    font-weight: 500;
                    padding: 0 0 30px 0;
                }
            }
        }
        .red-modal {
            .modal-body {
                background-color: #d14b52;

                h2 {
                    color: #ffffff;
                }
                .subtext {
                    color: #ffffff;
                    opacity: 0.85;

                    .link {
                        padding: 20px 0 15px 0;
                    }
                }
                select {
                    width: 100%;
                    color: #666666;
                    font-size: 14px;
                    padding: 10px;
                    margin: 5px 0 10px 0;
                    border: 1px solid #dadada;
                }
                .custom-button {
                    border: 1px solid #ffffff;
                    background-color: #ffffff;
                    color: #d14b52;
                }
                ul {
                    color: #ffffff;
                    text-align: left;
                }
                .error {
                    display: none;
                }
            }
        }
        .contact-modal {
            .modal-body {
                .error {
                    display: none;
                }
            }
        }
    }
}
.contact {
    span, label {
        color: #2c2c2b;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
    }
    input[type="text"], input[type="email"], textarea, select {
        width: 100%;
        color: #666666;
        font-size: 14px;
        padding: 10px;
        margin: 5px 0 10px 0;
        border: 1px solid #dadada;
    }
    textarea {
        height: 150px;
        resize: none;
    }
    input[type="submit"] {
        border: none;
        text-transform: uppercase;
    }
    .confirm {
        margin: 60px 0 15px 0;
        text-align: center;

        .title {
            color: #167b7b;
            font-size: 30px;
            font-weight: 600;
            padding: 10px 0;
        }
        .text {
            color: #616161;
            font-size: 15px;
            font-weight: 500;
        }
    }
    ul {
        font-size: 12px;
        color: #e86a6a;
        font-style: italic;
        font-weight: 400;
        list-style: none;
        padding: 0 0 0 15px;
        margin: 0;
        width: -webkit-fill-available;
    }
}
.navbar {
    width: 100%;

    .navbar-toggler {
        width: inherit;
        text-align: right;
    }
}
.mobile-menu {
    background-color: #b47c5a;

    .nav-item {
        text-transform: uppercase;
        padding: 0 30px;
        text-align: center;

        a {
            color: #ffffff;
            font-weight: 500;
        }
        .header-image-login {
            width: 40px;
            height: 40px;
            background-color: #d14b52;
            border-radius: 50%;
            margin: auto;
            background-position: center;
            background-size: cover;
        }
    }
    .connectregister {
        padding: 10px 30px;
        border-bottom: 1px solid #eae7de;
    }
    .search {
        border-bottom: 1px solid #eae7de;
    }
    .external {
        opacity: 0.7;
    }
    .connecteduser {
        padding: 10px 0;
    }
}
.error-page {
    h1 {
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
        text-align: center;
        padding: 60px 0 15px 0;
    }
    .text {
        font-size: 16px;
        color: #2c2c2b;
        text-align: center;
        padding: 15px 15px 35px 15px;
        line-height: 35px;
    }
    .pills {
        padding: 15px;
        a {
            color: #ffffff;
            text-transform: uppercase;
        }
        .red-pill {
            background-color: #e20707;
        }
        .blue-pill {
            background-color: #29a9e0;
        }
    }
}
.register {
    .picto {
        padding-right: 5px;
    }
}
.page-big-header {
    height: 500px;

    .background {
        position: absolute;
        width: 100%;
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.7;
        -webkit-filter: grayscale(80%); /* Safari */
        filter: grayscale(80%);
    }
    .background-dark {
        position: absolute;
        width: 100%;
        height: 500px;
        opacity: 0.6;
        background-color: #000000;
    }
    .big-header-infos-author {
        padding: 110px 15px 0 15px;
    }
    .big-header-infos-story {
        padding: 80px 15px 0 15px;
    }
    .big-header-infos {
        .header-image-author {
            position: relative;
            width: 260px;
            height: 260px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            border: 3px solid #e5e5e5;

            @media screen and (max-width: $break-xlarge) {
                width: 200px;
                height: 200px;
            }
            @media screen and (max-width: $break-large) {
                width: 160px;
                height: 160px;
            }
        }
        .title {
            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
            font-size: 55px;
            font-weight: 500;
            color: #ffffff;
            text-transform: none;
            line-height: 55px;
            padding: 15px 0 20px 0;
        }
        span {
            font-size: 14px;
            font-weight: 300;
            color: #ffffff;

            a {
                color: #ffffff;
                border-bottom: 1px solid #ffffff;
            }
        }
        .social {
            position: relative;
            margin: 20px 0;
            padding: 15px 0;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            height: 50px;

            a {
                color: #ffffff;
                font-size: 14px;
                text-transform: uppercase;
            }
            a.social-mini {
                float: right;
                margin: 0 10px;
            }
            .story-stats-box {
                float: left;
                color: #ffffff;
                padding: 0 15px 0 0;
            }
        }
        .header-infos {
            padding-top: 25px;

            h1 {
                color: #ffffff;
            }
        }
        .header-image-story {
            position: relative;
            width: 100%;
            height: 320px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: 3px solid #e5e5e5;

            @media screen and (max-width: $break-xlarge) {
                width: 200px;
                height: 280px;
            }
            @media screen and (max-width: $break-large) {
                width: 160px;
                height: 220px;
                margin-top: 45px;
            }
        }
        .list-tag {
            float: left;
            padding: 0 5px 0 0;

            span {
                color: #ffffff;
                font-size: 11px;
                padding: 5px 10px;
                background-color: #b47c5a;
                text-transform: uppercase;
            }
        }
    }
}
.account-menu {
    .account-menu-link {
        width: 20%;
        float: left;
        font-size: 17px;
        padding: 30px 0 20px 0;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        border-bottom: 2px solid #ffffff;

        @media screen and (max-width: $break-large) {
            width: 33%;
        }
        @media screen and (max-width: $break-medium) {
            width: 50%;
        }

        a {
            color: #333333;
        }
    }
    .account-menu-link.active {
        border-bottom: 2px solid #d14b52;

        a {
            color: #d14b52;
        }
    }
}
.account-avertissement {
    padding: 15px 30px;
    background-color: #d14b52;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;

    a {
        color: #ffffff;
        border-bottom: 1px dotted;
        font-weight: 500;
    }
    i {
        font-size: 18px;
        animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    }
}
@keyframes blinker { from { opacity: 1; } to { opacity: 0; } }
.page-detail {
    padding: 40px 15px 15px 15px;

    .text {
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        font-size: 20px;
        line-height: 30px;
        color: #2c2c2b;
        padding-bottom: 20px;

        span {
            font-size: 60px;
            color: #d14b52;
            font-weight: 700;
        }
    }
    .stats {
        text-align: center;

        .title {
            position: relative;
            font-size: 18px;
            color: #d14b52;
            text-transform: uppercase;
            padding: 0 15px 20px 15px;
            font-weight: 600;
        }
        .stat-box {
            position: relative;
            padding: 15px 15px;
            font-size: 13px;
            color: #333333;
            font-weight: 500;
            text-transform: uppercase;

            span {
                font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                color: #d14b52;
                font-size: 70px;
                font-weight: 700;
                line-height: 50px;
            }
        }
        .action {
            margin: 5px 0;
            text-transform: uppercase;
        }
        .contact {
            background-color: #e5e5e5;

            a {
                color: #2c2c2b;
            }
        }
        .follow {
            a {
                color: #ffffff;
            }
        }
        .unfollow {
            background-color: #ffffff;
            border: 2px solid #d14b52;

            a {
                color: #d14b52;
            }
        }
        .report {
            padding: 15px 15px;

            a {
                font-size: 14px;
                color: #616161;
                font-weight: 300;
                font-style: italic;
                text-transform: none;
                border-bottom: 1px solid #616161;
            }
        }
        .author-img {
            height: 160px;
            width: 160px;
            margin: auto;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
        }
        .author-infos {
            padding: 20px 0 30px 0;

            span {
                font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                color: #2c2c2b;
                font-size: 30px;
                font-weight: 600;
            }
            a {
                font-size: 13px;
                color: #d14b52;
                border-bottom: 1px solid #d14b52;
                text-transform: uppercase;
            }
        }
        .custom-button-ho {
            background-color: #db9534;
        }
    }
    .header {
        padding: 0 0 40px 0;

        .custom-button {
            position: relative;
            width: 225px;
            background-color: #f6f6f6;
            color: #2c2c2b;
            padding: 15px 35px;
            border-radius: 30px;
            text-align: center;
            font-size: 14px;
            float: left;
            font-weight: 500;
            margin-right: 5px;
        }
        .rating {
            color: #d14b52;
            text-transform: uppercase;
        }
        h1 {
            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
            color: #d14b52;
            font-size: 55px;
            font-weight: 600;
            line-height: 55px;
        }
        .saga {
            font-size: 22px;
            color: #333333;
            font-weight: 300;
            padding-bottom: 15px;
        }
        .status {
            font-size: 14px;
            padding-bottom: 10px;
        }
        .status-encours {
            color: #23a44e;

            a {
                color: #23a44e;
                border-bottom: 1px dotted #23a44e;
            }
        }
        .status-enhiatus {
            color: #a42323;
        }
        .status-enrecriture {
            color: #a47023;

            a {
                color: #a47023;
                border-bottom: 1px dotted #a47023;
            }
        }
        .infos {
            font-size: 14px;
            color: #acacac;
            line-height: 25px;
        }
    }
    .chapter {
        padding: 10px 0;
        border-bottom: 1px solid #c2c2c2;

        a {
            font-size: 14px;
            color: #333333;
            font-weight: 500;
        }
        .chapter-title {
            padding-top: 8px;
        }
        .social{
            padding-top: 8px;

            .story-stats-box {
                float: right;
            }
        }
        .round {
            position: relative;
            font-size: 14px;
            width: 25px;
            height: 25px;
            background-color: #f6f6f6;
            color: #b5b5b5;
            border-radius: 50%;
            padding: 2px;
            text-align: center;
            float: left;
            margin-right: 10px;
            margin-top: 5px;
            cursor: pointer;
        }
        .state {
            font-size: 12px;
            color: #b5b5b5;
            font-weight: 500;
            text-transform: uppercase;

            .state-text {
                padding: 0;
                cursor: pointer;
            }
        }
        .state.active {
            .round {
                background-color: #d14b52;
                color: #ffffff;
            }
            .state-text {
                color: #d14b52;
                padding-top: 10px;
            }
        }
    }
    .story-stats-box {
        position: relative;
        font-size: 14px;
        float: left;
        padding: 0 20px 0 0;
        color: #666666;
    }
    .content {
        padding-bottom: 30px;
    }

    .pal-hide {
        text-align: right;
        padding: 15px;

        a {
            color: #2c2c2b;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 10px 0 0;
            margin-bottom: 0;
        }
    }
}
.account-params-item {
    .round {
        position: relative;
        font-size: 14px;
        width: 25px;
        height: 25px;
        background-color: #f6f6f6;
        color: #b5b5b5;
        border-radius: 50%;
        padding: 2px;
        text-align: center;
        float: left;
        margin-right: 10px;
        margin-top: 5px;
    }
    .active {
        round {
            background-color: #d14b52;
            color: #ffffff;
        }
    }
    .message {
        text-align: center;
        padding: 15px;

        a.custom-button {
            color: #ffffff;
            text-transform: uppercase;
        }
    }
}
.account-content {
    background-color: #f6f6f6;

    h1 {
        font-family: 'Poiret One', cursive;
        color: #2c2c2b;
        font-size: 40px;
        text-transform: uppercase;
    }
    .see-read {
        padding: 15px 0;
        text-align: right;
        font-size: 15px;
        font-weight: 500;

        a {
            color: #616161;

            i.check {
                color: #d14b52;
                font-size: 20px;
            }
        }
    }
    .red-button {
        width: 100%;
        background-color: #d14b52;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 30px;
        border-radius: 5px;
    }
    a.red-button {
        color: #ffffff;
        float: right;
        text-align: center;
        margin: 20px 0 0 0;
    }
    .account-image-story {
        position: relative;
        width: 100%;
        height: 320px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 10px;
    }
    .account-image {
        width: 80px;
        height: 80px;
        background-color: #d14b52;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }
    .subscriber-image {
        width: 130px;
        height: 130px;
        background-color: #d14b52;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        margin: auto;
    }
    .account-submenu {
        padding: 15px;

        .account-submenu-item {
            padding: 15px 0;

            a {
                color: #666666;
                font-weight: 500;

                i {
                    padding-right: 15px;
                }
            }
        }
    }
    .account-story-item {
        background-color: #ffffff;
        padding: 15px;
        margin: 10px 0;
        border-radius: 5px;

        .story-img {
            width: 100%;
            height: 230px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        .maj {
            color: #666666;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 5px;
        }
        .story-genres {
            .story-genres-box {
                background-color: #b5b5b5;
            }
        }
        .title {
            a {
                color: #333333;
                font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                font-weight: 600;
                font-size: 32px;
                line-height: 30px;
            }
        }
        .chapters {
            color: #666666;
            font-size: 14px;
            font-weight: 500;
            padding-left: 5px;
        }
        .saga {
            font-size: 22px;
            color: #333333;
            font-weight: 300;
            padding-bottom: 5px;
        }
        .authors {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid #b5b5b5;

            a {
                color: #b47c5a;
                font-weight: 600;
            }
        }
        .authors-pal {
            border-bottom: 1px solid #b5b5b5;
            padding-bottom: 15px;
            margin-bottom: 10px;
        }
        .action-buttons {
            padding: 45px 15px;
            text-align: center;

            .custom-button.remove {
                background-color: #e5e5e5;
                font-size: 14px;
                text-transform: uppercase;
            }
            a.remove {
                color: #2c2c2b;
                font-weight: 600;
            }
        }
        .social {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid #b5b5b5;
        }
        .social-pal {
            border-top: none;
            margin-top: auto;
            padding-top: auto;
        }
        .no-followers {
            font-size: 18px;
            color: #2c2c2b;
            font-weight: 500;
            padding: 20px 0 25px 0;
            text-align: center;

            .message {
                padding: 10px 0;
            }
            a.custom-button:hover {
                color: #ffffff;
            }
        }
        .item {
            margin: 5px 15px;

            .name {
                font-size: 14px;
                color: #2c2c2b;
                font-weight: 500;
                padding: 5px;
                text-align: center;
            }
        }
        .help {
            font-style: italic;
            padding: 10px 10px;
            text-align: right;
        }
    }
    .account-params-item {
        padding: 30px 45px;

        a {
            color: #b47c5a;
        }

        .title {
            color: #2c2c2b;
            font-size: 25px;
            font-weight: 500;
            padding-bottom: 15px;
            border-bottom: 1px solid #2c2c2b;
            margin-bottom: 15px;
        }
        label {
            color: #2c2c2b;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 10px 0 0 0;
            margin-bottom: 0;
        }
        .info {
            color: #666666;
            font-size: 12px;
            font-weight: 300;
        }
        input[type="text"], input[type="email"], input[type="password"], textarea {
            width: 100%;
            color: #666666;
            font-size: 14px;
            padding: 10px;
            margin: 5px 0 10px 0;
            border: 1px solid #dadada;
        }
        input[type="file"] {
            width: 100%;
        }
        .resume {
            height: 200px;
            resize: none;
        }
        .checkbox {
            div {
                padding: 10px 5px;
            }
        }
        select {
            width: 100%;
            color: #666666;
            font-size: 14px;
            padding: 10px;
            margin: 5px 0 10px 0;
            border: 1px solid #dadada;
        }
        .state-text {
            padding-top: 4px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .active {
            .state-text {
                color: #d14b52;
            }
        }
        .account-notifs {
            padding: 8px 0 0 0;

            .state-text {
                padding: 10px;
                font-weight: 500;
            }
        }
        .chapter {
            .chapter-edit {
                a {
                    color: #666666;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .chapter-delete {
                a {
                    color: #d14b52;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .picture {
            label {
                padding-top: 0;
                width: 100%;
            }
            input {
                padding: 10px 0;
            }
        }
    }
    ul {
        font-size: 12px;
        color: #e86a6a;
        font-style: italic;
        font-weight: 400;
        list-style: none;
        padding: 0 0 0 15px;
        margin: 0;
        width: -webkit-fill-available;
    }
    ul.active {
        color: #23a44e;
    }
    .chapter-content {
        padding: 0 15px 15px 15px;
    }
    .account-warning {
        background-color: #db9534;
        color: #ffffff;
        padding-top: 30px;

        .title {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 500;
        }
        .date {
            font-size: 14px;
        }
        .message {
            padding-left: 15px;
            border-left: 3px solid #ffffff;
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 16px;
        }
        .plus {
            font-size: 16px;
            text-transform: uppercase;
            border-bottom: 2px solid #ffffff;
            cursor: pointer;
            padding-top: 15px;
        }
        .plus-texte {
            background-color: #ffffff;
            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
            font-size: 20px;
            line-height: 30px;
            color: #2c2c2b;
            padding: 30px 45px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            display: none;
            margin-bottom: 15px;

            p {
                text-indent: 30px;
            }
        }
    }
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #d14b52;
    border-color: #d14b52;
}

.toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
}
.toggle:before {
    content: "";
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: #adb5bd;
    border-radius: 8px;
    transition: background 0.2s ease;
}
.toggle span {
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
    transition: all 0.2s ease;
}
.toggle span:before {
    content: "";
    position: absolute;
    display: block;
    margin: -18px;
    width: 56px;
    height: 56px;
    background: rgba(209, 75, 82, 0.5);
    border-radius: 50%;
    transform: scale(0);
    opacity: 1;
    pointer-events: none;
}

.cbx {
    display: none;
}
.cbx:checked + .toggle:before {
    background: #d14b52;
}
.cbx:checked + .toggle span {
    background: #ffffff;
    border: 2px solid #d14b52;
    transform: translateX(20px);
    transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
    box-shadow: 0 3px 8px rgba(209, 75, 82, 0.2);
}
.cbx:checked + .toggle span:before {
    transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease;
}

.authors {
    .authors-filters {
        padding: 20px 0;
        border-bottom: 1px solid #eaeaea;

        @media screen and (max-width: $break-small) {
            padding: 20px 20px;
        }

        .authors-filter-box {
            float: left;
            width: 43px;
            text-align: center;
            font-size: 15px;
            padding: 10px 15px;

            a {
                color: #b5b5b5;
            }
        }

        .authors-filter-box.active a {
            color: #ffffff;
        }

        .authors-filter-box.active {
            background-color: #b47c5a;
            border-radius: 50%;
            width: 42px;

            @media screen and (max-width: $break-xlarge) {
                width: 42px;
            }
        }
    }
}
.authors-list {
    .authors-list-box {
        text-align: center;
        padding-top: 30px;

        .authors-list-img {
            width: 100%;
            height: 260px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin: auto;
            border-radius: 50%;

            @media screen and (max-width: $break-xlarge) {
                width: 200px;
                height: 200px;
            }
            @media screen and (max-width: $break-large) {
                width: 240px;
                height: 240px;
            }
        }
    }

    .over-author {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.8;
        padding: 70px 0 0 0;
        display: none;
        text-align: center;
        border-radius: 50%;

        span {
            font-size: 43px;
            color: #ffffff;
        }

        a {
            font-size: 14px;
            font-weight: 200;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 25px;
        }
    }
}
.author-list-tag {
    text-align: center;
    padding-top: 30px;

    span{
        color: #ffffff;
        font-size: 11px;
        padding: 5px 10px;
        background-color: #b47c5a;
        text-transform: uppercase;
    }
}
.author-list-stories {
    font-size: 14px;
    color: #666666;
    font-weight: 300;
    border-bottom: 1px solid #b47c5a;
    padding: 0 0 20px 0;
}
.author-list-name {
    padding: 15px 0 0 0;

    a {
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        font-size: 30px;
        font-weight: 600;
        color: #333333;
    }
}
.load-more {
    font-size: 14px;
    color: #d14b52;
    text-transform: uppercase;
    text-align: center;
    padding: 50px 0;
    line-height: 25px;

    i {
        font-size: 30px;
        cursor: pointer;
    }

    a {
        color: #d14b52;
        object {
            position: relative;
            border: 2px solid #d14b52;
            border-radius: 50%;
            padding: 10px 13px;
            margin: 10px;
        }
    }
}
.page-content {
    font-size: 16px;
    color: #2c2c2b;
    line-height: 35px;
    font-weight: 300;

    a {
        color: #b47c5a;
    }
    strong {
        font-weight: 600;
    }

    h1 {
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
        padding: 15px 0 15px 0;

        @media screen and (max-width: $break-large) {
            font-size: 50px;
        }
        @media screen and (max-width: $break-medium){
            font-size: 40px;
        }
    }
    h2 {
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        font-size: 28px;
        color: #2c2c2b;
        font-weight: 600;
        padding: 30px 0 10px 0;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
            padding-bottom: 10px;
        }
        li:before {
            font-family: "Font Awesome 5 Free";
            display: inline-block;
            content: "\f192";
            padding-right: 10px;
            color: #b47c5a;
        }
    }
    blockquote {
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        border-left: 6px solid #b47c5a;
        margin: 30px 0;
        padding: 0 30px;
        font-size: 30px;
        font-style: italic;
        line-height: 40px;
    }
    .date {
        color: #808080;
        font-size: 11px;
        font-weight: 500;
        padding: 15px 0 0 0;

        a {
            color: #808080;
            font-weight: 600;
        }
    }
    .back {
        color: #b47c5a;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 30px 0 3px;
        border-bottom: 1px solid #f1e9dc;
        margin: 0 0 30px 0;

        a {
            color: #b47c5a;
        }
    }
    .image {
        text-align: center;
        margin: 30px 0;

        .legend {
            color: #808080;
            font-size: 11px;
            font-weight: 500;
        }
    }
}
.stories-list {
    padding: 30px 15px 0 15px;

    .box-story {
        .events-list {
            .box-event {
                width: 100%;
                margin-bottom: 40px;
            }
        }
    }
    .comment-title {
        font-size: 18px;
        color: #2c2c2b;
        font-weight: 500;
        padding: 20px 0 20px 0;

        span {
            color: #b47c5a;
        }
    }
}
.events-list {
    .single-event {
        padding: 0 15px 15px 15px;
    }
}
.box-story {
    position: relative;
    width: 100%;
    height: 555px;
    float: left;
    padding: 0 5px 0 0;

    .story-img {
        width: 100%;
        height: 320px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .over-infos {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.8;
        padding: 95px 0 0 0;
        display: none;
        text-align: center;

        .over-infos-box {
            position: relative;
            width: 150px;
            padding: 15px 0;
            font-weight: 200;
            font-size: 20px;
            text-transform: uppercase;
            border-top: 1px solid #ffffff;
            margin: auto;

            i{
                font-size: 30px;
            }
            a {
                color: #ffffff;
            }
        }
        .over-infos-box:first-of-type {
            border-top: none;
        }
    }
    .story-title {
        position: relative;
        padding: 10px 0 0 0;
        text-align: left;
        font-weight: 300;
        clear: both;
        height: 125px;
        border-bottom: 1px solid #b47c5a;

        a {
            color: #666666;
        }
        a:hover {
            color: #b47c5a;
        }
        span {
            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
            font-size: 26px;
            font-weight: 600;

            a {
                color: #000000;
            }
        }
    }
    .story-stats {
        padding: 10px 0;

        .story-stats-box {
            position: relative;
            font-size: 14px;
            float: left;
            padding: 0 20px 0 0;
            color: #666666;
            width: 20%;

            i {
                font-size: 18px;
            }
            span {
                position: absolute;
                top: -7px;
                left: 7px;
                font-size: 10px;
                font-weight: 600;
                background-color: #ffffff;
                border: 1px solid #666666;
                border-radius: 5px;
                padding: 0 3px;
                line-height: initial;
            }
        }
    }
}
.box-event {
    height: auto;

    .story-img {
        height: 250px;
    }
    .story-title {
        height: auto;
    }
}
.story-genres {
    position: relative;
    padding: 15px 0;
    font-size: 11px;
    color: #ffffff;
    text-transform: uppercase;
    height: 71px;

    .story-genres-box {
        position: relative;
        float: left;
        padding: 5px 8px;
        margin: 0 3px 3px 0;
        background-color: #b47c5a;
        font-size: 10px;

        a {
            color: #ffffff;
        }
        @media screen and (max-width: $break-xlarge) {
            font-size: 8px;
            padding: 4px 5px;
        }
    }
}
.comments {
    .author-img {
        height: 70px;
        width: 70px;
        margin: auto;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }
    .single-item {
        margin: 30px 0;

        .comment-title {
            font-size: 18px;
            color: #2c2c2b;
            font-weight: 500;
            padding: 20px 0 20px 0;

            span {
                color: #b47c5a;
            }
        }
        .comment-title {
            a {
                font-size: 18px;
                color: #2c2c2b;
                font-weight: 500;
                padding: 20px 0 20px 0;
            }
            .tag {
                color: #ffffff;
                font-size: 11px;
                padding: 5px 10px;
                background-color: #b47c5a;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }
        textarea {
            border: 2px solid #f1e9dc;
            color: #b47c5a;
            font-size: 14px;
            padding: 15px;
            width: 100%;
            height: 130px;
            resize: vertical;
            line-height: 20px;
        }
        .comment-submit {
            margin: 10px 0;

            input[type="submit"] {
                border: none;
                color: #ffffff;
                text-transform: uppercase;
                width: 100%;
                padding: 10px 35px;
            }
            .submit-consign {
                font-size: 12px;
                color: #666666;
                font-style: italic;
                padding: 15px 0;
            }
        }
        .comment-answers {
            textarea {
                height: 75px;
            }
        }
        .loader {
            color: #d14b52;
            font-size: 20px;
            text-align: center;
            display: none;
        }
    }
    .comment-date {
        font-size: 13px;
        color: #b5b5b5;
        font-weight: 300;
    }
    .comment-body {
        padding: 0 0 10px 0;
        font-size: 14px;
        color: #666666;
        font-weight: 300;
        line-height: 25px;
    }
    ul {
        font-size: 12px;
        color: #e86a6a;
        font-style: italic;
        font-weight: 400;
        list-style: none;
        padding: 0 0 0 15px;
        margin: 0;
        width: -webkit-fill-available;

        li:before {
            content: '';
        }
    }
    .review-answer {
        ul {
            display: none;
        }
    }
}
.account-info-box {
    background-color: #ffffff;
    padding: 15px;
    margin: 15px 0;
    border-radius: 50px;

    .date {
        color: #616161;
        font-size: 14px;
        font-weight: 500;
        font-style: italic;
        padding-bottom: 7px;
    }
    .detail {
        color: #333333;
        font-size: 18px;
        font-weight: 500;

        a.link-black {
            color: #333333;
            font-weight: 600;
        }
        a.link-red {
            color: #d14b52;
            font-weight: 600;
        }
        a.link-comment {
            color: #d14b52;
            border-bottom: 1px solid #d14b52;
        }
    }
}
.account-info-box-read {
    opacity: 0.7;
}
.account-story-add-chapter {
    padding: 0;
    margin: 0;
    background-color: #d14b52;
    text-align: center;
    border-radius: 0 0 5px 5px;
    padding: 20px;

    a {
        color: #ffffff;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }
}
.page-stories {
    background-color: #f6f6f6;
    padding: 50px 15px 20px 15px;

    .title {
        font-size: 18px;
        color: #b47c5a;
        text-transform: uppercase;
        padding: 15px;
    }
}
.stories {
    .stories-filters {
        padding: 20px 0;
        border-bottom: 1px solid #eaeaea;

        .title {
            font-size: 22px;
            color: #b47c5a;
            padding: 10px 20px;
        }
        select {
            width: 100%;
            color: #666666;
            font-size: 14px;
            padding: 10px;
            margin: 5px 0 10px 0;
            border: 1px solid #dadada;
        }
        label {
            font-size: 15px;
            color: #616161;
            padding: 11px 0;
        }
        input[type="radio"] {
            padding: 15px 5px;
        }
        input[type="submit"] {
            border: none;
            background-color: #b47c5a;
            text-transform: uppercase;
        }
    }
    ul {
        font-size: 12px;
        color: #e86a6a;
        font-style: italic;
        font-weight: 400;
        list-style: none;
        padding: 0 0 0 15px;
        margin: 0;
        width: -webkit-fill-available;
    }
    form {
        input[type="radio"] {
            display: none;
        }
        label {
            padding: 7px;
            font-weight: 500;
            cursor: pointer;
        }
        .expanded-row {
            width: 32%;
            text-transform: uppercase;
            float: left;
            border: 1px solid #d14b52;
            color: #616161;
            height: 60px;
            margin: 3px;

            @media screen and (max-width: $break-large) {
                width: 48%;
            }
            @media screen and (max-width: $break-small) {
                width: 98%;
                height: initial;
            }
        }
        .selected {
            background-color: #d14b52;
            color: #ffffff;
        }
    }
}
#modal-search {
    width: 100%;
    background-color: #d3545b;
    opacity: 0.9;

    .modal-dialog {
        max-width: 600px;
        margin: auto;

        @media screen and (max-width: $break-medium) {
            width: 100%;
            margin: auto;
        }

        .modal-body {
            padding: 60px 0 0 0;
            background-color: #d3545b;

            h2 {
                font-family: 'Poiret One', cursive;
                font-size: 60px;
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 400;
                padding-bottom: 30px;
            }

            .subtext {
                color: #ffffff;
                font-size: 16px;
                font-weight: 200;
                padding-bottom: 45px;
            }

            input[type="text"] {
                font-size: 35px;
                color: #ffffff;
                font-weight: 200;
                border: none;
                border-bottom: 1px solid #ffffff;
                background-color: #d3545b;
                width: 70%;
                float: left;

                @media screen and (max-width: $break-medium) {
                    font-size: 25px;
                }
            }

            input[type="text"]::placeholder {
                color: #ffffff;
            }

            input[type="submit"] {
                font-size: 15px;
                color: #ffffff;
                font-weight: 500;
                border: none;
                border-bottom: 1px solid #ffffff;
                border-radius: 0;
                background-color: #d3545b;
                text-align: right;
                padding: 50px 5px 5px 0;
                width: 30%;
                float: left;

                @media screen and (max-width: $break-medium) {
                    font-size: 12px;
                    padding-top: 39px;
                    text-align: center;
                }
            }
        }
    }
}
.search-results {
    .box {
        height: 550px;
    }
    h2 {
        font-family: 'Poiret One', cursive;
        font-size: 40px;
        color: #333333;
        text-transform: uppercase;
        padding-top: 30px;
    }
}
.page-chapter {
    .header {
        width: 80%;
        text-align: center;
        margin: auto;

        h1 {
            font-size: 45px;
            color: #2c2c2b;
        }
        .social {
            margin: auto;

            i {
                margin-left: 10px;
            }
        }
        .author {
            margin: 30px 0 20px 0;
            font-size: 13px;
            color: #666666;
            line-height: 25px;

            .author-img {
                width: 50px;
                height: 50px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50%;
                margin: auto;
            }
            a {
                color: #d14b52;
                border-bottom: 1px solid #d14b52;
            }
        }
    }
    .notes {
        padding: 15px 0 45px 0;
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        font-size: 20px;
        color: #666666;
        font-style: italic;
    }
    .text {
        font-size: 20px;
        color: #2c2c2b;

        p {
            padding-bottom: 15px;
            text-indent: 45px;
        }
    }
    .text p:first-of-type:first-letter {
        font-size: 60px;
        color: #d14b52;
        font-weight: 700;
    }
    .stats {
        .story-infos {
            span {
                font-size: 30px;
                color: #2c2c2b;
                font-weight: 500;
                line-height: 30px;

                a {
                    color: #2c2c2b;
                }
            }
            select {
                margin: 30px 0;
                width: 80%;
                background-color: #f6f6f6;
                color: #666666;
                font-size: 14px;
                padding: 15px;
                border: none;
            }
        }
        .story-img {
            width: 160px;
            height: 185px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin: auto;
        }
        .contact {
            margin-top: 30px;
        }
    }
    .navigation {
        margin: 40px 0;
        padding: 40px 0;
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        text-align: center;

        .read {
            border: 2px solid #d14b52;
            background-color: #ffffff;
            padding: 20px 5px;
            border-radius: 50px;

            a {
                color: #d14b52;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        .read.active {
            background-color: #d14b52;

            a {
                color: #ffffff;
            }
        }
        .arrows {
            a {
                color: #b5b5b5;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;

                .round {
                    color: #ffffff;
                    background-color: #b5b5b5;
                    padding: 6px 12px;
                    margin: 0 7px;
                    border-radius: 50%;
                }
            }
        }
        .arrows.active {
            a {
                color: #d14b52;

                .round {
                    background-color: #d14b52;
                }
            }
        }
    }
}
.KW_progressContainer {
    left:0;
    width: 100%;
    height: 0.4em;
    margin-bottom: 0px;
    position: fixed;
    bottom: 0px;
    overflow: hidden;
    background-color: white;
    content: "";
    display: table;
    table-layout: fixed;
    z-index: 10;
}

.KW_progressBar {
    width: 0%;
    float: left;
    height: 100%;
    z-index:99;
    max-width: 100%;
    background-color:orange;
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}
.story-read-also {
    background-color: #f6f6f6;
}

progress {
    /* Positioning */
    position: fixed;
    left: 0;
    top: 0;

    /* Dimensions */
    width: 100%;
    height: 5px;

    /* Reset the appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of the default border in Firefox/Opera. */
    border: none;

    /* Progress bar container for Firefox/IE10+ */
    background-color: transparent;

    /* Progress bar value for IE10+ */
    color: #d14b52;
    z-index: 100;
}
progress::-webkit-progress-bar {
    background-color: transparent;
}

progress::-webkit-progress-value {
    background-color: #d14b52;
}

progress::-moz-progress-bar {
    background-color: #d14b52;
}
.home-slider {
    padding: 0;
}
.scroll-down {
    position: relative;
    margin: auto;
    text-align: center;
    top: -40px;

    a {
        background-color: #ffffff;
        padding: 20px 35px;
        border-radius: 40%;
    }
}
.home-info {
    text-align: center;
    padding-top: 45px;

    .red-circle {
        position: relative;
        width: 90px;
        height: 90px;
        background-color: #d14b52;
        border-radius: 50%;
        padding: 25px;
        margin: auto;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 20px;
        color: #323232;
    }
    .row {
        margin-bottom: 50px;
    }
    .see-rules {
        text-align: center;
    }
    .home-see-rules, .home-paen .home-see-paen {
        background-color: #ffffff;
        color: #d14b52;
        border: 2px solid #d14b52;
        text-transform: uppercase;
    }
    .item {
        padding-bottom: 15px;
    }
}
.home-stories {
    background-color: #f1e9dc;
    padding: 50px 0;
    text-align: center;

    h2{
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
    }
    h3 {
        font-size: 16px;
        color: #b47c5a;
        text-transform: uppercase;
        padding-bottom: 30px;
    }
    span{
        font-size: 16px;
        color: #333333;
        font-weight: 300;
        line-height: 30px;
    }
    .brown-circle {
        position: relative;
        width: 60px;
        height: 60px;
        background-color: #b47c5a;
        border-radius: 50%;
        padding: 15px;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 10px;
    }
    .see-stories {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .home-see-stories {
        background-color: initial;
        color: #b47c5a;
        border: 2px solid #b47c5a;
        text-transform: uppercase;
    }
    .story-stats-box {
        span {
            position: absolute;
            top: -7px;
            left: 15px !important;
            font-size: 10px;
            font-weight: 600;
            background-color: #ffffff;
            border: 1px solid #666666;
            border-radius: 5px;
            padding: 0 3px;
            line-height: initial;
        }
    }
}
.home-paen {
    h2 {
        font-family: 'Poiret One', cursive;
        font-size: 60px;
        color: #333333;
        text-transform: uppercase;
    }
    span{
        font-size: 16px;
        color: #333333;
        font-weight: 300;
        line-height: 30px;
    }
}
.home-paen {
    padding: 50px 0;
    text-align: center;

    object {
        position: relative;
        margin: 50px 0 20px 0;
    }
    .paen-box {
        height: 570px;
        padding: 0 20px 0 0;
        text-align: left;
    }
    .paen-img {
        width: 100%;
        height: 260px;
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;

        .paen-tag {
            color: #ffffff;
            font-size: 11px;
            text-transform: uppercase;
            background-color: #d14b52;
            padding: 5px 10px;
            position: relative;
            left: 0;
            float: left;
            margin: 235px 0 0 0;
        }
    }
    .paen-box {
        .paen-text {
            padding: 30px 0;

            .author {
                font-size: 14px;
                color: #666666;
                font-weight: 300;

                a {
                    font-size: 14px;
                    color: #666666;
                    font-weight: 300;
                }
            }
            .resume {
                position: relative;
                padding: 20px 0 0 0;
                font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                color: #acacac;
                font-size: 17px;
            }
        }
        .title {
            a {
                font-size: 21px;
                color: #333333;
            }
        }
    }
    .paen-box-big {
        .paen-text {
            height: 250px;
            background-color: #f6f6f6;
            padding: 30px;

            .title {
                a {
                    font-size: 30px;
                }
            }
            .resume {
                color: #666666;
            }
        }
    }
}
/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

.carousel {
    height: 500px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    z-index: 10;
    position: relative;
    text-align: left;
    left: 0;
    right: 0;
    margin-top: 90px;
}

/* Declare heights because of positioning of img element */
.carousel .item {
    height: 500px;
    background-color: #777;
}
.carousel-inner > .item > img {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    background-position: top;
}
.carousel-inner .item .background {
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: #000000;
    opacity: 0.5;
}

.carousel-indicators {
    bottom: initial;
    text-align: left;
    padding-top: 60px;
    left: 34%;
}
.carousel-indicators .active {
    position: relative;
    background-color: initial;
    border: 4px solid #ffffff;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    top: -4px;
}
.carousel-indicators li {
    background-color: #b47c5a;
    border: 4px solid #b47c5a;
    border: none;
    margin-right: 5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}
.carousel h1 {
    font-family: 'Poiret One', cursive;
    font-size: 64px;
    color: #ffffff;
    text-shadow: none;
    text-transform: uppercase;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.carousel h2 {
    font-size: 14px;
    color: #eae7de;
    text-shadow: none;
    text-transform: uppercase;
    padding-top: 0;
    margin-top: 20px;
}
.carousel h2 object {
    margin-right: 10px;
}

.carousel .slider-button {
    background-color: #b47c5a;
    text-transform: uppercase;
    text-shadow: none;
}
.carousel .slider-button:hover {
    color: #ffffff;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
    /* Navbar positioning foo */
    .navbar-wrapper {
        margin-top: 20px;
    }
    .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }

    /* The navbar becomes detached from the top, so we round the corners */
    .navbar-wrapper .navbar {
        border-radius: 4px;
    }

    /* Bump up size of carousel content */
    .carousel-caption p {
        margin: 25px 0 50px 0;
        font-size: 15px;
        line-height: 25px;
        text-shadow: none;
    }

    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        margin-top: 120px;
    }
}
.go-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 30px;
    height: 30px;
    padding: 5px 0;
    background-color: #d14b52;
    border-radius: 50%;
    text-align: center;

    a {
        color: #ffffff;
    }
}
.alert-reviews {
    color: #d14b52;
    padding: 5px;
}
.page-big-header-ho {
    height: 370px;

    .background {
        height: 370px;
        opacity: 1;
        -webkit-filter: grayscale(0%); /* Safari */
        filter: grayscale(0%);
    }
    .header {
        text-align: center;
        color: #ffffff;
        text-shadow: 3px 3px 5px black;

        h1 {
            padding: 0 0 15px 0;
            font-family: 'Poiret One', cursive;
            font-size: 72px;
            text-transform: uppercase;
        }
        .subtitle {
            font-size: 19px;
        }
        .picto {
            padding: 50px 0 15px 0;
            font-size: 40px;
        }
    }
    .custom-button {
        background-color: #db9534;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 45px;
        top: 30px;
    }
}
.ho-intro-container {
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;

    .ho-intro {

        .title {
            text-align: center;

            .indicator {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                color: #db9534;
                padding-top: 45px;
            }

            h2 {
                font-size: 45px;
                padding: 10px 0 15px 0;
                font-family: 'Poiret One', cursive;
                text-transform: uppercase;
            }

            .subtitle {
                font-size: 30px;
                font-weight: 500;
                padding: 15px 0;
                color: #2c2c2b;
            }
        }

        .text {
            font-size: 16px;
            line-height: 30px;
            padding: 15px 0;
            column-count: 2;
            column-gap: 50px;
        }

        .text-phase2 {
            column-count: initial;
        }

        .custom-button {
            background-color: #db9534;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 45px;
        }

        .ho-menu {
            .item {
                padding: 15px 0;
                border-bottom: 1px solid #eaeaea;
                width: 90%;

                a {
                    font-size: 14px;
                    color: #666666;

                    &.active {
                        font-weight: 600;
                        color: #db9534;
                    }
                }
            }
        }
    }

    .ho-progress {
        font-size: 18px;
        text-transform: uppercase;
        color: #db9534;
        font-weight: 500;
        padding: 30px;

        .bar {
            position: relative;
            width: 80%;
            height: 4px;
            margin: auto;
            padding: 10px;

            .full {
                position: relative;
                height: 4px;
                float: left;
                background-color: #db9534;
            }
            .empty {
                position: relative;
                height: 4px;
                float: left;
                background-color: #eae7de;
            }
        }
        .info {
            color: #616161;
            text-transform: initial;
            font-size: 11px;
            text-align: center;
        }
    }
}
.ho-categs-container {
    background-color: #202022;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    padding: 30px 15px;

    .ho-categs {
        .title {
            text-align: center;

            .indicator {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                color: #db9534;
                padding-top: 45px;
            }

            h2 {
                font-size: 45px;
                padding: 10px 0 30px 0;
                font-family: 'Poiret One', cursive;
                text-transform: uppercase;
                color: #db9534;
            }
        }
        .detail {
            padding: 15px;
            text-align: center;

            .picto {
                color: #db9534;
                font-size: 24px;
            }
            .title {
                color: #db9534;
                font-size: 19px;
                font-weight: 600;
                text-transform: uppercase;
                padding: 5px 0;
            }
            .text {
                color: #ededed;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
}
.ho-calendar {
    padding: 30px 15px;

    .title {
        text-align: center;
        background-image: url(/img/ho-zigouigoui.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        height: 245px;

        .indicator {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            color: #db9534;
            padding-top: 45px;
        }

        h2 {
            font-size: 45px;
            padding: 10px 0 30px 0;
            font-family: 'Poiret One', cursive;
            text-transform: uppercase;
        }
    }
    .detail {
        padding: 15px 15px 25px 15px;

        .title {
            color: #db9534;
            font-weight: 600;
            font-size: 25px;
            text-transform: uppercase;
            padding: 15px 15px;
            background-image: none;
            height: initial;

            span {
                background-color: #ffffff;
                padding: 15px 30px;
            }

            &:before {
                border-top: 2px solid #ededed;
                content:"";
                margin: 0 auto;
                position: absolute;
                top: 50%; left: 0; right: 0; bottom: 0;
                width: 95%;
                z-index: -1;
            }
        }
        .dates {
            padding: 15px 30px;
            font-size: 16px;
            color: #020202;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 28px;

            .date {
                font-size: 42px;
                font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                color: #db9534;
                font-style: italic;
                width: 100%;
                text-transform: lowercase;
                float: left;
            }
        }
        .text {
            padding: 15px 15px 0 15px;
            font-size: 15px;
            color: #202022;
            line-height: 30px;
        }
    }
}
.home-ho {
    background-color: #202022;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    padding: 30px 15px;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";

    .content {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";

        h2 {
            font-size: 60px;
            padding: 45px 0 15px 0;
            font-family: 'Poiret One', cursive;
            text-transform: uppercase;
            color: #db9534;
        }

        .subtitle {
            font-size: 30px;
            color: #ededed;
            font-weight: 500;
            padding: 0 15px 15px 0;
        }
        .text {
            font-size: 16px;
            color: #ededed;
            line-height: 30px;
            padding: 0 15px 15px 0;
        }
        .custom-button {
            background-color: #db9534;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 45px;
        }
    }
}
#modal-ho {
    .modal-dialog {
        max-width: 900px;

        .modal-content {
            width: 900px;

            .ho {
                text-align: left;

                .indicator {
                    text-align: center;
                    font-size: 14px;
                    color: #db9534;
                    text-transform: uppercase;
                    font-weight: 500;
                }
                h2 {
                    font-family: 'Poiret One', cursive;
                    text-transform: uppercase;
                    font-size: 45px;
                    font-weight: 400;
                }

                .subtext-left {
                    text-align: left;

                    .length {
                        font-size: 12px;
                        color: #666;
                        font-style: italic;
                        padding: 0 0 20px 0;
                    }

                    .error {
                        display: none;
                    }
                }
            }
        }
    }
}
.notifs-show {
    position: fixed;
    bottom: 85px;
    right: 15px;
    width: 500px;
    display: none;

    .account-info-box {
        box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5) !important;
    }

    @media screen and (max-width: $break-large) {
        display: none !important;
    }
}
.display-none {
    display: none;
}
.account-image {
    width: 80px;
    height: 80px;
    background-color: #d14b52;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.account-red {
    background-color: #d14b52 !important;
    color: #ffffff !important;

    .title {
        color: #ffffff !important;
        border-bottom: 1px solid #ffffff !important;
    }
    label {
        color: #ffffff !important;
    }
    a {
        color: #ffffff !important;
        border-bottom: 1px dotted #ffffff !important;
    }
    .parentAuthorizationFile {
        border: 1px solid #ffffff;
        padding: 10px 30px;
        margin: 15px 0;
        background-color: #ffffff;
        color: #d14b52;
        border-radius: 5px;
        opacity: 0.8;

        i {
            margin-right: 10px;
        }
        a {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            border-bottom: none !important;
            color: #d14b52 !important;
        }
        .delete {
            float: right;
        }
    }
    .small {
        text-transform: initial !important;
        font-weight: 400 !important;
    }
    .file {
        padding-top: 15px;
        text-align: center;
        font-size: 60px;
    }
    .red-button {
        background-color: #ffffff;
        color: #d14b52;
    }
    a.red-button {
        color: #d14b52 !important;
        font-size: 16px;
        font-weight: 600;
    }
    .info {
        color: #ffffff !important;
    }
}

.home-dixit {
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    padding: 30px 15px;
    border-top: 2px solid #d14b52;
    border-bottom: 2px solid #d14b52;
    background: linear-gradient(45deg, #d14b52 30%, #d14b52 30%, transparent 30%), linear-gradient(45deg, transparent 75%, #d14b52 75%, #d14b52 100%);
    mix-blend-mode: darken;

    .content {

        h2 {
            font-size: 60px;
            padding: 45px 0 15px 0;
            font-family: 'Poiret One', cursive;
            text-transform: uppercase;
            mix-blend-mode: screen;
            color: #d14b52;
        }

        .subtitle {
            font-size: 30px;
            font-weight: 500;
            padding: 0 15px 15px 0;
            mix-blend-mode: screen;
        }
        .text {
            font-size: 16px;
            line-height: 30px;
            padding: 0 15px 15px 0;
            mix-blend-mode: screen;
        }
        .custom-button {
            background-color: #d14b52;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 45px;
            width: 250px;
            float: right;
            mix-blend-mode: screen;
        }
        .image-left {
            padding-top: 45px;
            .image {
                width: 300px;
                height: 400px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: center;
                border: 2px solid #d14b52;
            }
        }
    }
}
.dixit {
    .thumbs {
        padding: 20px 0;
        border-bottom: 1px solid #eaeaea;

        @media screen and (max-width: $break-small) {
            padding: 20px 20px;
        }

        .box {
            float: left;
            width: 43px;
            text-align: center;
            font-size: 15px;
            padding: 10px 15px;

            a {
                color: #b5b5b5;
            }
        }

        .box.active a {
            color: #ffffff;
        }

        .box.active {
            background-color: #b47c5a;
            border-radius: 50%;
            width: 42px;

            @media screen and (max-width: $break-xlarge) {
                width: 42px;
            }
        }
    }
}
.dixit-intro-container {
    mix-blend-mode: darken;

    h3 {
        padding: 15px 0 0 0;
        font-family: 'Poiret One', cursive;
        font-size: 30px;
        text-transform: uppercase;
    }

    .ho-intro {
        mix-blend-mode: screen;

        .title {

            .indicator {
                color: #d14b52;
            }

            h2 {
            }

            .subtitle {
            }
        }

        .text {
        }

        .custom-button {
            background-color: #d14b52;
        }
        .ho-menu {
            .item {

                a {
                    &.active {
                        color: #d14b52;
                    }
                }
            }
        }
        .images {
            padding: 15px;

            .item {
                text-align: center;
                padding: 15px;

                .image {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: center;
                    border: 2px solid #d14b52;
                    margin: auto;
                }
                .image-first {
                    width: 300px;
                    height: 400px;
                }
                .image-second {
                    width: 200px;
                    height: 266px;
                }
                .week {
                    padding: 10px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #d14b52;
                }
                .title {
                    font-weight: 500;
                }
            }
            .week2 {
                opacity: 0.8;
            }
            .week3 {
                opacity: 0.65;
            }
            .week4 {
                opacity: 0.5;
            }
        }
        .infos {
            text-align: center;
            font-size: 14px;
            font-style: italic;
            padding: 15px;
        }
    }
}
.histoiresor_participation_form {
    .hide {
        display: none;
    }

    .histoire_or_error {
        padding: 15px;
        text-align: center;
        color: #d14b52;
        font-size: 16px;
    }

    .working {
        padding: 30px;
        text-align: center;
        font-size: 20px;
        color: #db9534;
    }

    .success {
        text-align: center;

        span {
            color: #db9534;
            font-weight: 600;
        }
    }
}
.ho-phase2 {
    padding: 30px;

    .categories {
        .title {
            padding: 15px 15px 30px 15px;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 500;
            color: #db9534;
            text-align: center;
        }

        .item {
            padding: 15px;
            border-bottom: 1px solid #eaeaea;

            a {
                font-weight: 500;
            }
        }
    }

    .stories {
        .category {
            padding: 0 15px 30px 15px;

            .title {
                font-family: 'Poiret One', cursive;
                font-size: 37px;
                color: #db9534;
            }
            .info {
                font-size: 16px;
                line-height: 30px;
                padding-bottom: 15px;
            }
            .ho-progress {
                font-size: 16px;
                text-transform: uppercase;
                color: #db9534;
                font-weight: 500;
                padding: 10px 0;

                .text {
                    font-size: 14px;
                    float: left;
                }

                .bar {
                    position: relative;
                    width: 68%;
                    height: 4px;
                    padding: 10px;
                    float: left;

                    .full {
                        position: relative;
                        height: 4px;
                        float: left;
                        background-color: #db9534;
                    }
                    .empty {
                        position: relative;
                        height: 4px;
                        float: left;
                        background-color: #eae7de;
                    }
                }
                .percent {
                    float: left;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .stories {
                .box-story {
                    height: 390px;

                    .story-title {
                        border-bottom: none;
                        height: initial;

                        span {
                            font-size: 20px;
                            line-height: 18px;
                        }
                    }
                    .story-img {
                        height: 250px;

                        .lu {
                            color: #db9534;
                            background-color: #ffffff;
                            padding: 3px 8px;
                            border-bottom-right-radius: 5px;
                            font-weight: 500;
                            font-size: 12px;
                            text-transform: uppercase;
                            width: 52px;
                        }
                        .vote {
                            position: absolute;
                            text-align: center;
                            top: 120px;
                            width: 100%;

                            .custom-button {
                                color: #ffffff;
                                background-color: #db9534;
                                font-weight: 500;
                                font-size: 13px;
                                text-transform: uppercase;
                            }
                        }
                        .a-vote {
                            position: absolute;
                            width: 98%;
                            height: 250px;
                            background-color: #db9534;
                            opacity: 0.75;
                            text-align: center;
                            color: #ffffff;
                            font-weight: 600;
                            text-transform: uppercase;
                            padding-top: 120px;
                        }
                    }
                    .details {
                        padding: 15px;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: #db9534;
                        font-weight: 600;

                        a {
                            color: #db9534;
                        }
                    }
                }
            }
        }
    }
}
.modal-ho {
    .modal-dialog {
        max-width: 900px !important;

        .modal-content {
            width: 900px !important;
            padding: 30px;

            .modal-body {
                padding: 45px 30px !important;

                .story-img {
                    width: 100%;
                    height: 350px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .categories {
                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: initial;
                        line-height: 20px;
                        color: #000000;
                        font-family: 'Montserrat', sans-serif;
                        padding: 15px 15px 5px 15px;
                    }
                    .item {
                        font-size: 14px;
                        font-weight: 500;
                        text-transform: uppercase;
                        color: #db9534;
                        padding: 10px;
                    }
                }
                .category-name {
                    background-color: #f3eacf;
                    color: #db9534;
                    padding: 5px 10px;
                    margin-bottom: 5px;
                    width: fit-content;
                    font-weight: 600;
                }
                .story-title {
                    font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                    font-size: 55px;
                    font-weight: 500;
                    color: #db9534;
                    text-transform: none;
                    font-size: 36px;
                    line-height: 34px;

                    i {
                        font-size: 20px;
                    }
                    a {
                        color: #db9534;
                        border-bottom: none;
                    }
                }
                .story-author {
                    color: #db9534;
                    font-size: 13px;
                    font-weight: 500;
                    padding: 5px;

                    a {
                        color: #db9534;
                        border-bottom: 1px solid #db9534;
                    }
                }
                .quote {
                    font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                    font-size: 18px;
                    line-height: 24px;
                    padding: 30px 0 20px 0;
                    border-bottom: 1px solid #eae7de;

                    &:first-letter {
                        font-size: 60px;
                        color: #db9534;
                        font-weight: 600;
                    }
                }
                .explanation {
                    padding: 20px 0 0 0;
                    line-height: 24px;

                    strong {
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #000000;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
}
.modal-ho-vote {
    .confirm {
        font-weight: 600;
        color: #000000;
        text-transform: uppercase;
        padding: 0 15px 30px 15px;
        font-size: 14px;

        span {
            color: #db9534;
            font-size: 14px;
        }
    }
    .buttons {
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;

        .accept {
            background-color: #db9534;
            color: #ffffff !important;
            border: none !important;
        }
        .refuse {
            background-color: #eae7de;
            color: #616161 !important;
            border: none !important;
        }
    }
    .info {
        color: #616161;
        text-transform: none;
        font-size: 13px !important;
        text-align: center;
        padding-top: 30px;
    }
}
.modal-delete-account {
    .confirm {
        font-weight: 600;
        color: #000000;
        text-transform: uppercase;
        padding: 0 15px 30px 15px;
        font-size: 14px;
    }
    .buttons {
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;

        .accept {
            background-color: #d14b52;
            color: #ffffff !important;
            border: none !important;
        }
        .refuse {
            background-color: #eae7de;
            color: #616161 !important;
            border: none !important;
        }
    }
    .info {
        color: #616161;
        text-transform: none;
        font-size: 13px !important;
        text-align: center;
        padding-top: 30px;
    }
}
.first-category {
    .story-img {
        width: 100%;
        height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .story-title {
        color: #db9534;

        a {
            color: #db9534;
        }
        span {
            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
            font-weight: 600;
            font-size: 32px;
            line-height: 30px;
        }
    }

    .quote {
        font-family: "Crimson Text script=all rev=2", "Adobe Blank";
        font-size: 18px;
        line-height: 24px;
        padding: 30px 0 20px 0;
        height: 330px;
        overflow: hidden;

        &:first-letter {
            font-size: 60px;
            color: #db9534;
            font-weight: 600;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
            width: 100%;
            height: 200px;
        }
    }
}
.ho-categs-all {
    h2 {
        color: #ffffff !important;
    }
    .all-categories {
        .item {
            height: 700px;

            .category {
                font-family: 'Poiret One', cursive;
                font-size: 37px;
                color: #db9534;
                text-align: center;
                opacity: 0.8;
                height: 100px;
            }

            .story-img {
                width: 65%;
                height: 400px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin: auto;
                margin-bottom: 30px;
                margin-top: 30px;
                opacity: 0.7;
            }

            .story-title {
                color: #ffffff;
                opacity: 0.7;
                text-align: center;
                width: 70%;
                margin: auto;

                a {
                    color: #ffffff;
                }

                span {
                    font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 30px;
                }

                .character {
                    font-size: 18px;
                }
            }
        }
    }
}
.admin-moderation-rapide {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
    margin: 10px 0;
    border: 2px solid #db9534;

    a {
        color: #db9534;

        i {
            font-size: 22px;
        }
    }
}
#modal-moderation-rapide {
    .modal-dialog {
        max-width: 900px;

        .modal-content {
            width: 900px;

            .modal-body {
                text-align: left;
                background-color: #db9534;

                h2 {
                    font-family: 'Poiret One', cursive;
                    text-transform: uppercase;
                    font-size: 45px;
                    font-weight: 400;
                    color: #ffffff;
                }

                .subtext {
                    color: #ffffff;

                    select {
                        width: 100%;
                        padding: 10px;
                        opacity: 0.8;
                    }
                    textarea {
                        width: 100%;
                        height: 100px;
                        padding: 10px;
                        opacity: 0.8;
                    }
                    .message {
                        background-color: #ffffff;
                        color: #616161;
                        padding: 15px;
                        text-align: left;
                        margin-top: 5px;
                        border-radius: 15px;
                        opacity: 0.8;
                    }
                    .info {
                        font-weight: 400;
                        font-size: 12px;
                        text-align: left;
                    }
                    .custom-button {
                        border: 0px;
                        background-color: #ffffff;
                        color: #db9534;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 18px;
                        opacity: 0.9;
                    }
                }
            }
        }
    }
}
.cover-warning {
    padding-top: 30px !important;
    text-align: center;
    i {
        font-size: 50px;
        color: #d14b52;
        animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    }
}
.home-actus {
    background-color: #d14b52;
    padding: 30px 15px;

    h2 {
        color: #ffffff;
        padding-bottom: 15px;
    }
    .box-story {
        .story-genres {
            padding: 0;
            height: 20px;
            .story-genres-box {
                display: none;
            }
        }
        .story-title {
            border-bottom: none;
            a {
                color: #ffffff;
                opacity: 0.9;
            }
            p {
                color: #ffffff;
                opacity: 0.7;
            }
        }
    }
}

.calendar {
    hr {
        border-top-color: #d14b52;
    }
    .title {
        padding: 30px 15px 15px 15px;
        .text {
            position: absolute;
            top: 20px;

            h2 {
                font-size: 40px;
                color: #d14b52;
                background-color: #ffffff;
                text-transform: uppercase;
                padding-right: 20px;
            }
        }
    }
    .month {
        .day {
            float: left;
            width: 14%;
            height: 150px;
            border: 1px solid #707070;
            margin: 0 2px 2px 0;

            .date {
                font-weight: 600;
                color: #707070;
                padding: 5px 15px;
                width: 30%;
                text-align: center;
                border-bottom-right-radius: 10px;
                border-bottom: 1px solid #707070;
                border-right: 1px solid #707070;
            }
            &-none {
                opacity: 0.5;
            }
        }
        .active {
            background-color: #e0e0e0;
            .date {
                background-color: #707070;
                color: #ffffff;
                border: none;
            }
        }
        .event {
            position: absolute;
            padding: 15px;
            width: 99%;
            color: #ffffff;
            margin-left: 2px;
            border-radius: 50px;
            text-align: center;
            opacity: 0.9;

            a {
                color: #ffffff;
                border-bottom: 1px dotted #ffffff;
            }
        }
        .w {
            &-1 {
                width: 13.6%;
            }
            &-2 {
                width: 27.8%;
            }
            &-3 {
                width: 42%;
            }
            &-4 {
                width: 56.2%;
            }
            &-5 {
                width: 70.3%;
            }
            &-6 {
                width: 84.5%;
            }
            &-7 {
                width: 98.7%;
            }
        }
        .start {
            &-1 {

            }
            &-2 {
                left: 14.2%;
            }
            &-3 {
                left: 28.3%;
            }
            &-4 {
                left: 42.45%;
            }
            &-5 {
                left: 56.6%;
            }
            &-6 {
                left: 70.8%;
            }
            &-7 {
                left: 85%;
            }
        }
        .color {
            &-yellow {
                background-color: #db9534;
            }
            &-red {
                background-color: #d14b52;
            }
            &-green {
                background-color: #23a44e;
            }
            &-brown {
                background-color: #b47c5a;
            }
        }
        .line {
            &-1 {
                top: 40px;
            }
            &-2 {
                top: 95px;
            }
        }
    }
}
.home-calendar {
    background-color: #333333;
    h2 {
        color: #ffffff;
        padding-bottom: 15px;
    }
    .month {
        .day {
            border-color: #ffffff;
            .date {
                border-color: #ffffff;
                color: #ffffff;
            }
        }
        .active {
            background-color: #707070;
            .date {
                background-color: #333333;
            }
        }
    }
    .see-calendar {
        text-align: center;
        margin-top: 50px;

        .custom-button {
            background-color: initial;
            color: #ffffff;
            border: 2px solid #ffffff;
            text-transform: uppercase;
        }
    }
}
.parathon {
    &-big-header {
        .header {
            text-shadow: 3px 3px 20px black;

            h1 {
                font-weight: 600;
            }
        }
    }
    &-intro-container {
        .intro {
            .title {
                .indicator {
                    color: #23a44e;
                }
            }
            button {
                .custom-button {
                    background-color: #23a44e;
                    border: none;

                    &:disabled {
                        background-color: #707070;
                        cursor: not-allowed;
                    }
                }
            }
        }
        .inscription {
            .title {
                .indicator {
                    color: #23a44e;
                }

                h2 {
                    color: #23a44e;
                    font-weight: 600;
                }
            }
            .texte {
                color: #ffffff;
                font-size: 16px;
                line-height: 30px;
                padding: 15px 0;
                column-count: 2;
                column-gap: 50px;
            }
            .formulaire {
                .items {
                    padding: 30px 0 0 0;
                    .item {
                        margin-bottom: 15px;
                        .element {
                            font-family: "Crimson Text script=all rev=2", "Adobe Blank";
                            font-size: 30px;
                            font-weight: 500;
                            font-style: italic;
                            color: #202022;
                            width: 100%;
                            text-align: center;
                            padding: 30px 15px;
                            background-color: #ffffff;
                            cursor: pointer;
                            border: 3px solid #23a44e;
                            line-height: 25px;

                            span {
                                font-size: 42px;
                                font-weight: 600;
                                font-style: normal;
                                color: #23a44e;
                            }
                        }
                    }
                    .active {
                        .element {
                            border-color: #ffffff;
                            background-color: #23a44e;

                            span {
                                color: #ffffff;
                            }
                        }
                    }
                }
                .custom-button {
                    margin-top: 15px;
                    background-color: #23a44e;
                    text-transform: uppercase;
                    font-weight: 600;
                    &-off {
                        background-color: #707070;
                        margin-right: 5px;
                        font-weight: 500;
                    }
                }
            }
        }
        .title {
            .indicator {
                color: #23a44e;
            }

            h2 {
                color: #23a44e;
                font-weight: 600;
            }
        }
        .detail {
            .title {
                color: #23a44e;
            }
            .dates {
                .date {
                    color: #23a44e;
                }
            }
        }

        .compteur-total {
            text-align: center;
            color: #23a44e;
            padding: 30px 0 15px 0;
            font-family: 'Poiret One', cursive;
            font-size: 36px;
            text-transform: uppercase;
            font-weight: 600;
        }

        .bloc-compteur {
            margin-top: 15px;
            padding: 30px;
            border-radius: 10px;
            color: #23a44e;
            border: 2px solid #23a44e;
            text-align: center;
            height: 300px;

            h2 {
                font-family: 'Poiret One', cursive;
                font-weight: 600;
            }

            input[type="text"] {
                width: 80%;
                margin: 0;
                padding: 15px 35px;
                border-radius: 30px;
                color: #5a5a5a;
                border: 2px solid #23a44e;
            }
            .custom-button {
                width: 80%;
                margin: auto;
                margin-top: 15px;
                background-color: #23a44e;

                a {
                    color: #ffffff;
                    font-weight: 600;
                }
            }
            .buttons {
                .left {
                    float: left;
                    width: 50%;
                    input {
                        width: 100%;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        padding: 13px 35px;
                        text-align: center;
                    }
                }
                .right {
                    float: left;
                    width: 50%;
                    margin-top: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
        .bloc-trucopoints {
            margin-top: 15px;
            padding: 30px;
            border-radius: 10px;
            background-color: #23a44e;
            color: #ffffff;
            text-align: center;
            height: 300px;

            h2 {
                font-family: 'Poiret One', cursive;
                font-weight: 600;
            }
            .grille {
                margin-top: 30px;
                .item {
                    position: relative;
                    width: 20%;
                    float: left;
                    padding: 15px 0;
                    height: 75px;
                    font-size: 30px;
                }
            }
        }

        .bloc-classement {
            margin-top: 30px;
            padding: 0;
            border: 1px solid #23a44e;
            border-radius: 15px;

            .entete {
                background-color: #23a44e;
                color: #ffffff;
                padding: 15px;
                margin-bottom: 10px;
                border-top-right-radius: 13px;
                border-top-left-radius: 13px;
                font-size: 20px;

                .picto {
                    float: left;
                    padding-right: 15px;
                    font-size: 30px;
                    top: -10px;
                    position: relative;
                    color: #db9534;
                }
            }

            .utilisateur {
                padding: 10px 30px;

                .pseudo {
                    font-weight: 600;
                    color: #23a44e;
                }
                .image {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    float: left;
                    margin-right: 10px;
                    background-position: center;
                    background-size: cover;
                    padding-top: 10px;
                    color: #ffffff;
                    font-weight: 600;
                    text-align: center;
                    font-size: 20px;
                    text-shadow: 0 0 10px #000000;
                }
            }
        }
        .bloc-titre {
            padding: 30px 15px 0 15px;
            h3 {
                font-family: 'Poiret One', cursive;
                font-size: 36px;
            }
        }
    }
}

.espace-discussion {
    .bloc-discussion {
        border-top: 1px solid #666666;
        border-right: 1px solid #666666;
        border-top-right-radius: 15px;
        /*border-image: linear-gradient(to right, #ffffff, #666666) 1;*/
        padding: 20px;
        margin-top: 15px;
        margin-bottom: 30px;

        .titre {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }
        .icon-read {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 26px;
            text-align: center;
            width: 50px;
            height: 50px;
            padding-top: 5px;
        }
        .new {
            color: #ffffff;
            background-color: #666666;
            border-top-right-radius: 14px;
            border-bottom-left-radius: 15px;
        }
        .texte {
            padding-top: 15px;
            .image {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                float: left;
                margin-right: 10px;
                background-position: center;
                background-size: cover;
            }
        }
        .reponses {
            padding-top: 10px;
            font-size: 13px;
            .image {
                width: 50px;
                height: 50px;
            }
            .pictos {
                padding-top: 20px;
                margin-top: 20px;
                border-top: 1px solid #cecece;
                text-align: center;

                i {
                    font-size: 30px;
                }
                span {
                    position: absolute;
                    top: -5px;
                    left: 55px !important;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: #ffffff;
                    border: 1px solid #666666;
                    border-radius: 5px;
                    padding: 0 3px;
                    line-height: initial;
                }
            }
        }
        .points {
            text-align: center;
            font-size: 22px;
        }
    }
    .bloc-discussion-detail {
        border-bottom: 1px solid #b5b5b5;
        .entete {
            position: relative;
            min-height: 175px;
            margin-bottom: 20px;

            .separateur {
                margin: auto;
                width: 50%;
                border-bottom: 1px solid #b5b5b5;
                padding-bottom: 30px;
            }
            h2 {
                font-family: 'Montserrat', sans-serif;
                color: #616161;
                font-size: 30px;
            }

            .utilisateur {
                padding: 10px 30px;

                .pseudo {
                    font-weight: 600;
                    color: #23a44e;
                }

                .image {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    float: left;
                    margin: 0;
                    margin-right: 10px;
                    background-position: center;
                    background-size: cover;
                    padding-top: 10px;
                    color: #ffffff;
                    font-weight: 600;
                    text-align: center;
                    font-size: 20px;
                    text-shadow: 0 0 10px #000000;
                }

                .date {
                    color: grey;
                    font-size: 11px;
                    font-weight: 500;
                    padding: 15px 0 0;
                    float: left;
                    top: -25px;
                    position: relative;
                }
            }
        }
        .corps {
            .like {
                position: relative;
                text-align: center;

                i {
                    font-size: 45px;
                    color: #666666;
                }
                span {
                    position: relative;
                    top: -25px;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: #ffffff;
                    border: 1px solid #666666;
                    border-radius: 5px;
                    padding: 0 3px;
                    line-height: initial;
                }
            }
            .actif {
                i {
                    color: #d14b52;
                }
            }
        }
        &-reponse {
            padding-top: 25px;
            .entete {
                min-height: 100px;
            }

            &-formulaire {
                border-bottom: none;

                .single-item {
                    margin: 30px 0;

                    .comment-title {
                        font-size: 18px;
                        color: #2c2c2b;
                        font-weight: 500;
                        padding: 20px 0 20px 0;

                        span {
                            color: #b47c5a;
                        }
                    }
                    textarea {
                        border: 2px solid #f1e9dc;
                        color: #b47c5a;
                        font-size: 14px;
                        padding: 15px;
                        width: 100%;
                        height: 130px;
                        resize: vertical;
                        line-height: 20px;
                    }
                    .comment-submit {
                        margin: 10px 0;

                        input[type="submit"] {
                            border: none;
                            color: #ffffff;
                            text-transform: uppercase;
                            width: 100%;
                            padding: 10px 35px;
                            background-color: #23a44e;
                        }
                    }
                }
            }
        }
    }
}